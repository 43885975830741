<template>
  <el-container class="cityDe">
    <el-header style="padding: 0" height="100%">
      <vheadere class="hidden-sm-and-down"></vheadere>
      <ivheaderae class="hidden-md-and-up"></ivheaderae>
      <div class="top-img">
        <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/D-topImg.png" alt="" />
        <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/D-topImg-m.png" alt="" />
        <div class="top-img-top"><p>Start-up Policies</p></div>
        <div class="ec-top-foot">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><router-link to="/">Home</router-link></el-breadcrumb-item>
            <el-breadcrumb-item>Start-up Policies</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </el-header>
    <div class="header-nav hidden-md-and-up">
      <el-row>
        <el-col :xs="16" :sm="16" :md="16">
          <div class="sub-h-nav">
            <el-row v-show="subShow">
              <el-col :span="12" v-for="(item, index) in navTitle" v-bind:key="index">
                <div class="sub-select" @click="subSelect(index)">{{ item.txt }}</div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="8" :sm="8" :md="8">
          <div class="main-h-nav1">
            <div class="menu" @click="menuOpen">
              <i class="el-icon-arrow-right" :class="menuShow ? 'is-open' : ''"></i>
              <span>NAVIGATION</span>
            </div>
            <transition name="menu-fade">
              <div class="menu-list" v-show="menuShow">
                <ul>
                  <li class="menu-item" @click="mainSelect('1')">Start-up Policies</li>
                  <li class="menu-item" @click="mainSelect('2')">Essential Knowledge</li>
                  <li class="menu-item" @click="mainSelect('3')">Tax & Law</li>
                  <li class="menu-item" @click="mainSelect('4')">Visa & Immigrant</li>
                  <li class="menu-item" @click="mainSelect('5')">Setting up a company</li>
                  <div class="back-btn" @click="menuShow = false">
                    <i class="el-icon-arrow-right"></i>
                    <span>back</span>
                  </div>
                </ul>
              </div>
            </transition>
          </div>
          <!--            <div class="main-h-nav">-->
          <!--              <el-menu  mode="vertical" default-active="2"  @open="handleOpen" @close="handleClose" class="el-menu-vertical-demo" background-color="#000000b3"  text-color="#C8C8C8" active-text-color="#10B7D1">-->
          <!--                <el-submenu index="1">-->
          <!--                  <template slot="title">-->
          <!--                    <span>导航</span>-->
          <!--                  </template>-->
          <!--                  <div>-->
          <!--                    <el-menu-item-group index="2">-->
          <!--                      <el-menu-item  @click="mainSelect('1');">创业补贴扶持政策</el-menu-item>-->
          <!--                      <el-menu-item  @click="mainSelect('2')">创业必备知识</el-menu-item>-->
          <!--                      <el-menu-item  @click="mainSelect('3')">税务、法务须知</el-menu-item>-->
          <!--                      <el-menu-item  @click="mainSelect('4')">签证选择与移民</el-menu-item>-->
          <!--                      <el-menu-item  @click="mainSelect('5')">公司设立指引</el-menu-item>-->
          <!--                      &lt;!&ndash;                    <div>返回</div>&ndash;&gt;-->
          <!--                    </el-menu-item-group>-->
          <!--                  </div>-->
          <!--                </el-submenu>-->
          <!--              </el-menu>-->
          <!--            </div>-->
        </el-col>
      </el-row>
    </div>
    <el-main>
      <div class="w mida">
        <el-row type="flex">
          <el-col :sm="24" :md="24" :lg="24">
            <div class="mian-right">
              <div class="nav"><div>NAVIGATION</div></div>
              <el-tabs class="mii" :tab-position="tabPosition" v-model="acName">
                <el-tab-pane class="cyyy" label="Start-up Policies" name="1">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="50%" src="../../assets/images/en/A/SUB.png" alt />
                    <img class="hidden-md-and-up" width="75%" src="../../assets/images/en/A/SUB-m.png" alt />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-tabs v-model="activeName" @tab-click="handleClick" class="mian-left-l">
                        <el-tab-pane style="font-size: 0.3rem" label="" name="first">
                          <el-collapse accordion>
                            <div class="tem-img" v-html="subsidies1">
                              <!-- <img class="hidden-sm-and-down" width="100%" :src="subsidies1[0]" alt="" />
                              <img class="hidden-md-and-up" width="100%" :src="subsidies1[1]" alt="" /> -->
                              <div class="cnts-item i-one">
                                <h1>Subsidy for high-level foreign talent in Shenzhen</h1>
                                <ul>
                                  <li>
                                    <div>
                                      <i>
                                        <span class="i">01</span>
                                        Overview of high-level foreign talent in Shenzhen :
                                      </i>
                                      <p>
                                        High-level foreign talent refers to high-level foreign experts and returning students from overseas involved in three areas, namely:
                                        enterprise technology and innovative enterprises; scientific research and health education; arts, culture and sports. High-level foreign
                                        experts are classified into Category A and B, while returning students from overseas are classified into Category A, B and C. Category A
                                        talent refers to the national leading talent specified in Measures for the Determination of High-Level Professional Talent of Shenzhen
                                        (Trial); Category B talent refers to local leading talent; and Category C talent refers to reserve talent.
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <i>
                                        <span class="i">02</span>
                                        Basic eligibility criteria for high-level foreign talent :
                                      </i>
                                      <p>
                                        1、Abides by the law, possesses good occupational ethics and a rigorous research attitude, adheres to science and facts and the spirit of
                                        teamwork and cooperation;
                                      </p>
                                      <p>
                                        2、High-level foreign experts must have an employment contract of more than one year with the hiring organization. Returning students from
                                        overseas must be high-achieving individuals who intend to be entrepreneurs in Shenzhen or have signed an employment contract with an
                                        organization based in Shenzhen.
                                      </p>
                                      <p>3、Meets the age requirement:</p>
                                      <p>(1) Category A high-level foreign talent must be 60 years old or below;</p>
                                      <p>(2) Category B high-level foreign talent must be 55 years old or below;</p>
                                      <p>(3) Category C high-level foreign talent must be 40 years old or below.</p>
                                      <el-row class="one-left-row" type="flex" style="flex-wrap: wrap;">
                                        <el-col :sm="24" :md="8" :lg="8">
                                          <div>
                                            <div class="left"><img src="../../assets/images/D/one-code1.png" alt="" /></div>
                                            <div class="right">
                                              <p>Specific applicant criteria :</p>
                                              <h3>Category A :</h3>
                                              <h3>RMB 3 million</h3>
                                              <p>
                                                Nobel laureates, recipients of prestigious international awards, academicians of the Chinese Academy of Sciences and Chinese Academy
                                                of Engineering, leaders of renowned enterprises or world-renowned organizations, recipients of prestigious international awards for
                                                specialized fields, etc.
                                              </p>
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :sm="24" :md="8" :lg="8">
                                          <div>
                                            <div class="left"><img src="../../assets/images/D/one-code2.png" alt="" /></div>
                                            <div class="right">
                                              <p>Specific applicant criteria :</p>
                                              <h3>Category B :</h3>
                                              <h3>RMB 2 million</h3>
                                              <p>
                                                Internationally-renowned academicians, provincial-level leading talent, heads of internationally renovwned tertiary institutions,
                                                recipients of prestigious international awards for specialized fields, etc.
                                              </p>
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :sm="24" :md="8" :lg="8">
                                          <div>
                                            <div class="left"><img src="../../assets/images/D/one-code3.png" alt="" /></div>
                                            <div class="right">
                                              <p>Specific applicant criteria :</p>
                                              <h3>Category C :</h3>
                                              <h3>RMB1.6 million</h3>
                                              <p>
                                                Individuals who have served as assistant professors at prestigious foreign universities, core members of teams of high-level foreign
                                                talent, performers in world-renowned orchestras, principal inventors of patented inventions, etc.
                                              </p>
                                            </div>
                                          </div>
                                        </el-col>
                                      </el-row>
                                    </div>
                                  </li>
                                </ul>
                                <h1 class="h-1">Income tax subsidy for high-level foreign talent and talent in urgent demand in Qianhai District of Shenzhen</h1>
                                <i class="i-1" style="padding: .6rem 0 .2rem 0.3rem;">Funding criteria:</i>
                                <el-row class="one-left-row2">
                                  <el-col :sm="24" :md="16" :lg="16" class="left">
                                    <ul>
                                      <li>Subsidy rate = (paid income tax amount - taxable income)*15%</li>
                                      <li>15% of the excess of paid income tax amount over t.3he taxableincome will be subsidized</li>
                                      <li>Income tax is exempt for the amount subsidized.</li>
                                    </ul>
                                    <p>Enquiry details:</p>
                                    <p>0755-88988988 (8:00-12:00/13:30-19:00 on working days)</p>
                                    <p>Office address:</p>
                                    <p>Service Center, Qianhai E Station, Building A, No. 1, Qianwan 1st Road, Qianhai Shenzhen-Hong Kong Cooperation Zone</p>
                                  </el-col>
                                  <el-col :sm="24" :md="8" :lg="8" class="right">
                                    <p style="text-align: center;">For more detaills</p>
                                    <p style="text-align: center;">please scan the QR code</p>
                                    <img src="../../assets/images/D/one-code4.png" alt="" />
                                  </el-col>
                                </el-row>
                              </div>
                            </div>
                          </el-collapse>
                        </el-tab-pane>
                        <el-tab-pane style="font-size: 0.3rem" label="" name="second">
                          <el-col>
                            <div v-html="subsidies2">
                              <div class="tem-img">
                                <div class="policy-centent-box">
                                  <div class="bg-number">01</div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-18 el-col-lg-18">
                                    <div class="policy-centent">
                                      <div class="main-title">Funding for Shenzhen Peacock Teams</div>
                                      <div class="sub-title">Targeted applicants:</div>
                                      <div class="content-txt">
                                        <p>Innovative enterprises formed by teams of high-level foreign and local talent.</p>
                                        <p>
                                          Focus areas of subsidy:Internet, biology, new energy, new materials, new generation IT, energy saving and environmental protection,
                                          maritime, aerospace, health and life sciences, robotics, wearable devices, smart devices or other emerging industries.
                                        </p>
                                      </div>
                                      <div class="sub-title">Funding criteria:</div>
                                      <div class="content-txt">
                                        <p>
                                          Funding for single projects ranges from RMB 10 million to 100 million, with an average funding amount of RMB 20 million. Funding for foreign
                                          youth teams is RMB 20 million maximum. For teams that are attached to enterprises, the funding amount shall not be higher than 50% of the
                                          total project budget declared.
                                        </p>
                                      </div>
                                      <div class="sub-title">Enquiry details:</div>
                                      <div class="content-txt">
                                        <p>Contact number: 88121039, 88102204</p>
                                        <p>Address: Administrative Services West Hall, Civic Center.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-6 el-col-lg-6">
                                    <div class="code-box">
                                      <div class="code">
                                        <img
                                          width="80%"
                                          src="/FH-WEB/plugins/ueditor/jsp/upload/image/20190627/1561636861519049807.png"
                                          title="1561636861519049807.png"
                                          alt="code.png"
                                        />
                                      </div>
                                      <p class="code-txt">
                                        For more detaills
                                        <br />
                                        please scan the QR code
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="policy-centent-box">
                                  <div class="bg-number">02</div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-18 el-col-lg-18">
                                    <div class="policy-centent">
                                      <div class="main-title">Special subsidy for rental of premises by innovative enterprises formed by high-level foreign talent in Shenzhen</div>
                                      <div class="sub-title">Targeted applicants:</div>
                                      <div class="content-txt">
                                        <p>
                                          Subsidy for rental of premises by enterprises that have received the special enterprise funding or team funding for innovative enterprises
                                          formed by high-level foreign talent, or the funding by Guangdong Province for innovation research teams.
                                        </p>
                                      </div>
                                      <div class="sub-title">Funding criteria:</div>
                                      <div class="content-txt">
                                        <p>
                                          1.The number of allocations are restricted, due to a control over the annual special subsidy amount for innovative enterprises formed by
                                          high-level foreign talent.
                                        </p>
                                        <p>
                                          2. Within two years of its establishment, the enterprise’s rent will be subsidized at a rate of RMB 30/m²/month for an area of up to 500 m².
                                          On the third year of its establishment, the enterprise’s rent will be subsidized at a rate of RMB 15/m²/month for an area of up to 500 m².
                                          The application deadline for the subsidy is December 31, 2017.
                                        </p>
                                      </div>
                                      <div class="sub-title">Enquiry details:</div>
                                      <div class="content-txt">
                                        <p>Contact number: 88121039, 88102204</p>
                                        <p>Address: Administrative Services West Hall, Civic Center.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-6 el-col-lg-6">
                                    <div class="code-box">
                                      <div class="code">
                                        <img
                                          width="80%"
                                          src="/FH-WEB/plugins/ueditor/jsp/upload/image/20190627/1561636861519049807.png"
                                          title="1561636861519049807.png"
                                          alt="code.png"
                                        />
                                      </div>
                                      <p class="code-txt">
                                        For more detaills
                                        <br />
                                        please scan the QR code
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="policy-centent-box">
                                  <div class="bg-number">03</div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-18 el-col-lg-18">
                                    <div class="policy-centent">
                                      <div class="main-title">
                                        Special subsidy for technology innovation projects ofinnovative enterprises formed by high-level foreign talent in Shenzhen
                                      </div>
                                      <div class="sub-title">Targeted applicants:</div>
                                      <div class="content-txt">
                                        <p>
                                          Funding for research and development projects and projects for the practical application of research outcomes led by high-level foreign
                                          talent.
                                        </p>
                                        <p>
                                          Focus areas of subsidy:Internet, biology, new energy, new materials, new generation IT and other strategic emerging industries; maritime,
                                          aerospace, health and life sciences, and other future potential industries; high-tech manufacturing and technological areas that improve
                                          people’s quality of living.
                                        </p>
                                      </div>
                                      <div class="sub-title">Funding criteria:</div>
                                      <div class="content-txt">
                                        <p>
                                          The average funding amount is RMB 1 million, due to a control over the annual special subsidy amount for innovative enterprises formed by
                                          high-level foreign talent.
                                        </p>
                                      </div>
                                      <div class="sub-title">Enquiry details:</div>
                                      <div class="content-txt">
                                        <p>Contact number: 88121039, 88102204</p>
                                        <p>Address: Administrative Services West Hall, Civic Center.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-6 el-col-lg-6">
                                    <div class="code-box">
                                      <div class="code">
                                        <img
                                          width="80%"
                                          src="/FH-WEB/plugins/ueditor/jsp/upload/image/20190627/1561636861519049807.png"
                                          title="1561636861519049807.png"
                                          alt="code.png"
                                        />
                                      </div>
                                      <p class="code-txt">
                                        For more detaills
                                        <br />
                                        please scan the QR code
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="policy-centent-box">
                                  <div class="bg-number">04</div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-18 el-col-lg-18">
                                    <div class="policy-centent">
                                      <div class="main-title">Funding for intellectual property development projectsin Shenzhen (copyright registration for computer software)</div>
                                      <div class="sub-title">Targeted applicants:</div>
                                      <div class="content-txt">
                                        <p>Enterprises and individual entrepreneurs who have obtained the Certificate of Copyright Registration for Computer Software.</p>
                                      </div>
                                      <div class="sub-title">Funding criteria:</div>
                                      <div class="content-txt">
                                        <p>
                                          1.The applicant is required to register with the Copyright Protection Center of China within one year of completing the development of a
                                          computer software, and to apply for the subsidy within six months of obtaining the Certificate of Copyright Registration for Computer
                                          Software. Each software is subsidized for RMB 600.
                                        </p>
                                        <p>
                                          2.For individuals or enterprises represented by a Shenzhen intellectual property agency or the Shenzhen branch of a larger scale of a
                                          foreign intellectual property agency (where the latter’s paid tax amount for the previous year needs to be RMB 200,000 or above), the
                                          subsidy for each software item will be increased by RMB 300.
                                        </p>
                                      </div>
                                      <div class="sub-title">Enquiry details:</div>
                                      <div class="content-txt">
                                        <p>Telephone enquiry: 0755-12315，0755-88127758</p>
                                        <p>Office: Administrative Services East Hall, 1st Floor, Zone B, Fuzhong 3rd Road, Futian District, Shenzhen</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-6 el-col-lg-6">
                                    <div class="code-box">
                                      <div class="code">
                                        <img
                                          width="80%"
                                          src="/FH-WEB/plugins/ueditor/jsp/upload/image/20190627/1561636861519049807.png"
                                          title="1561636861519049807.png"
                                          alt="code.png"
                                        />
                                      </div>
                                      <p class="code-txt">
                                        For more detaills
                                        <br />
                                        please scan the QR code
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div class="policy-centent-box">
                                  <div class="bg-number">05</div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-18 el-col-lg-18">
                                    <div class="policy-centent">
                                      <div class="main-title">Recognition of national high-tech enterprises</div>
                                      <div class="sub-title">Targeted applicants:</div>
                                      <div class="content-txt"><p>Companies recognized as national high-tech enterprises.</p></div>
                                      <div class="sub-title">Benefits</div>
                                      <div class="content-txt">
                                        <p>
                                          Article 28 of the Enterprise Income Tax Law of the People’s Republic of China provides that high-tech enterprises are entitled to a 15%
                                          reduction in income tax rate, which is a 40% decrease of the original 25% tax rate.
                                        </p>
                                      </div>
                                      <div class="sub-title">Enquiry details:</div>
                                      <div class="content-txt">
                                        <p>Telephone enquiry: 0755-88127373, 0755-88100075</p>
                                        <p>Office: Administrative Services West Hall, Zone B, Civic Center, Fuzhong 3rd Road, Futian District, Shenzhen</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="el-col el-col-24 el-col-sm-24 el-col-md-6 el-col-lg-6">
                                    <div class="code-box">
                                      <div class="code">
                                        <img
                                          width="80%"
                                          src="/FH-WEB/plugins/ueditor/jsp/upload/image/20190627/1561636861519049807.png"
                                          title="1561636861519049807.png"
                                          alt="code.png"
                                        />
                                      </div>
                                      <p class="code-txt">
                                        For more detaills
                                        <br />
                                        please scan the QR code
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="tem-img">
                              <img class="hidden-sm-and-down" width="100%" :src="subsidies22[0]" alt="" />
                              <img class="hidden-md-and-up" width="100%" :src="subsidies22[1]" alt="" />
                            </div>
                            <div class="tem-img">
                              <img class="hidden-sm-and-down" width="100%" :src="subsidies23[0]" alt="" />
                              <img class="hidden-md-and-up" width="100%" :src="subsidies23[1]" alt="" />
                            </div>
                            <div class="tem-img">
                              <img class="hidden-sm-and-down" width="100%" :src="subsidies24[0]" alt="" />
                              <img class="hidden-md-and-up" width="100%" :src="subsidies24[1]" alt="" />
                            </div>
                            <div class="tem-img">
                              <img class="hidden-sm-and-down" width="100%" :src="subsidies25[0]" alt="" />
                              <img class="hidden-md-and-up" width="100%" :src="subsidies25[1]" alt="" />
                            </div> -->
                          </el-col>
                        </el-tab-pane>
                      </el-tabs>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane class="cyyy" label="Essential Knowledge" name="2">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/ENK.png" alt />
                    <img class="hidden-md-and-up" width="55%" src="../../assets/images/en/A/ENK-m.png" alt />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-tabs v-model="activeName" @tab-click="handleClick" class="mian-left-l">
                        <el-tab-pane label="" name="first">
                          <el-collapse accordion>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/01.png" alt class="enen" />
                                <img src="../../assets/images/D/concr.png" alt class="item-i" />
                                <span class="item-txt-t">Noun iterpretation</span>
                              </div>
                              <div v-html="essential.ONE"></div>
                              <!--                                <div v-html="essential.TWO"></div>-->
                              <!--                                <div v-html="essential.THREE"></div>-->
                              <!--                                <div v-html="essential.FOUR"></div>-->
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/02.png" alt class="enen" />
                                <img src="../../assets/images/D/director.png" alt class="item-i" />
                                <span class="item-txt-t">Relationship dlagram</span>
                              </div>
                              <div class="tem-img">
                                <img class="hidden-sm-and-down" width="100%" :src="essential12[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential12[1]" alt="" />
                              </div>
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/03.png" alt class="enen" />
                                <img src="../../assets/images/D/defect.png" alt class="item-i" />
                                <span class="item-txt-t">Advantages and disadvantages</span>
                              </div>
                              <div class="tem-img" v-html="essential13">
                                <!-- <img class="hidden-sm-and-down" width="100%" :src="essential13[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential13[1]" alt="" /> -->
                                <div class="cnts-item i-three3">
                                  <div class="cnts1">
                                    <h1>Type 1</h1>
                                    <h1>Wholly foreign-owned enterprise</h1>
                                    <div>
                                      <p>
                                        A wholly foreign-owned enterprise is a company, enterprise, commercial organization or individual that is established in China according to
                                        the laws of China and derives its total capital from foreign investors. These include sole ownership by natural persons and by legal
                                        entities.
                                      </p>
                                    </div>
                                    <div>
                                      <h3>Registration requirements</h3>
                                      <p>（1）A rented office space for the company’s registered address;</p>
                                      <p>（2）Sufficient start-up capital;</p>
                                      <p>（3）One legal representative (who can be a shareholder, and can act as the executive director and general manager);</p>
                                      <p>（4）One supervisor (who cannot be a senior executive);</p>
                                      <p>（5）One financial manager is required (if personnel is insufficient, it can be assumed temporarily by a director);</p>
                                      <p>
                                        （6）One person holding Chinese identification card is required as the company’s recipient of legal documents served within the country.
                                      </p>
                                    </div>
                                    <div>
                                      <h3>Pros:</h3>
                                      <p>
                                        （1）The shareholders own 100% of the shares and can operate independently without having to consider the position of Chinese investors. The
                                        company can determine its own strategic goals;
                                      </p>
                                      <p>
                                        （2） Compared to the restricted operations of the representative office, a wholly foreign-owned company can receive payments in RMB and
                                        issue invoices;
                                      </p>
                                      <p>
                                        （3）No minimum registered capital requirements - shareholders may decide on an appropriate amount of registered capital according to the
                                        company’s future development needs. If the capital becomes insufficient later on for the company’s operation, it can be increased. It is not
                                        advisable to set the registered capital amount at RMB 1;
                                      </p>
                                      <p>（4）No restrictions on registered capital - currencies such as RMB, HKD and USD may be used as the registered capital currency.</p>
                                    </div>
                                    <div>
                                      <h3>Const:</h3>
                                      <p>（1）Allowed to be set up only as a single-member limited liability company.</p>
                                      <p>（2）Apart from one foreign member, there must also be another foreign person acting as a company supervisor.</p>
                                    </div>
                                    <div>
                                      <h3>Case Study:</h3>
                                      <p>
                                        John Doe is interested in exploring the consultancy market in China and would like to set up a consultancy company under his name in China.
                                        After operating for half a year, Foreigner A would like to develop software for his online consultancy business, which requires him to set
                                        up a technology company. Foreigner A needs to find Foreigner B or Foreign Company B to form a joint venture company, as he is not allowed to
                                        create another sole proprietor under his name.
                                      </p>
                                    </div>
                                  </div>
                                  <div class="cnts2">
                                    <h1>Type 2</h1>
                                    <h1>Chinese-foreign joint venture enterprise</h1>
                                    <div>
                                      <p>
                                        A joint venture established in China according to the laws of China and jointly operated by a foreign company, enterprise, economic
                                        organization or individual and a Chinese company, enterprise, economic organization or individual.
                                      </p>
                                    </div>
                                    <div>
                                      <h3>Registration requirements:</h3>
                                      <p>（1）A rented office space for the company’s registered address;</p>
                                      <p>（2）Sufficient start-up capital;</p>
                                      <p>（3）At least one Chinese investor (enterprise) and one foreign investor (enterprise or individual);</p>
                                      <p>（4）Three directors and one supervisor;</p>
                                      <p>（5）One financial manager is required (if personnel is insufficient, it can be assumed temporarily by a director);/p></p>
                                      <p>
                                        （6）One person holding Chinese identification card is required as the company’s recipient of legal documents served within the country.
                                      </p>
                                    </div>
                                    <div>
                                      <h3>Pros:</h3>
                                      <p>
                                        （1）A joint venture can benefit from shared resources with both parties leveraging their mutual strengths, and has easier access into the
                                        Chinese market by relying on the networks and established branding of the Chinese enterprise;
                                      </p>
                                      <p>
                                        （2）The geographical advantage of a Chinese partner enterprise can help foreign investors reduce some of the financial expenditures
                                        reasonably and legally, thus lowering operational costs;
                                      </p>
                                      <p>（3）Foreign businesses are entitled to certain benefits.</p>
                                    </div>
                                    <div>
                                      <h3>Cons:</h3>
                                      <p>（1）The Chinese investors cannot be natural persons from China, but must be Chinese enterprises;</p>
                                      <p>（2）The investment percentage of foreign investors must not generally be lower than 25%;</p>
                                      <p>
                                        （3） A board of directors is mandatory, which will be the highest decision-making body of the company. The legal representative must be the
                                        chairman of the board and cannot be the general manager.
                                      </p>
                                    </div>
                                    <div>
                                      <h3>Case Study:</h3>
                                      <p>
                                        A, who is Australian, and B, who is Chinese, are good friends interested in exploring the cross-border e-commerce business in China. They
                                        have decided to form a company for the venture. They have been informed by the personnel at the Market Supervision and Regulation Bureau of
                                        Shenzhen Municipality that B has to first establish a domestic enterprise under his name, then form a company with A as a Chinese
                                        enterprise, or else they would not be allowed to register their company.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/04.png" alt class="enen" />
                                <img src="../../assets/images/D/garden.png" alt class="item-i" />
                                <span class="item-txt-t">Question Answer</span>
                              </div>
                              <div class="tem-img" v-html="essential14">
                                <!-- <img class="hidden-sm-and-down" width="100%" :src="essential14[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential14[1]" alt="" /> -->
                                <div class="cnts-item i-four">
                                  <ul>
                                    <li>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">Q</div>
                                        <div class="item-txt-c">How to update the details of new shareholders / legal representatives of a foreign-invested enterprise?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div><div class="item-txt-question">A</div></div>
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/three-code1.png" alt="" />
                                            <p>
                                              For more detaills
                                              <br />
                                              please scan the QR code
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">Q</div>
                                        <div class="item-txt-c">How to update the details of the new chief representative of a representative office?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div><div class="item-txt-question">A</div></div>
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/three-code2.png" alt="" />
                                            <p>
                                              For more detaills
                                              <br />
                                              please scan the QR code
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">Q</div>
                                        <div class="item-txt-c">
                                          How to cancel the registration of a foreign-invested enterprise (including a wholly foreign-owned enterprise or a Chinese-foreign joint
                                          venture enterprise)?
                                        </div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div><div class="item-txt-question">A</div></div>
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/three-code3.png" alt="" />
                                            <p>
                                              For more detaills
                                              <br />
                                              please scan the QR code
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">Q</div>
                                        <div class="item-txt-c">How to cancel the registration of a representative office?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div><div class="item-txt-question">A</div></div>
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/three-code4.png" alt="" />
                                            <p>
                                              For more detaills
                                              <br />
                                              please scan the QR code
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </el-collapse-item>
                          </el-collapse>
                        </el-tab-pane>
                        <el-tab-pane label="" name="second">
                          <div class="item-header" v-html="essential2.ONE"></div>
                          <el-collapse accordion>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/01.png" alt class="enen" />
                                <img src="../../assets/images/D/copy.png" alt class="item-i" />
                                <span class="item-txt-t">Copyright</span>
                              </div>
                              <div v-html="essential21">
                                <div class="copyright-info clearfloat">
                                  <div class="copyright-info-t">
                                    <span>Relevant authority: National Intellectual Property Department (please provide website and public QR code) (Chinese/English)</span>
                                    <span>Chinese website：http://www.ncac.gov.cn</span>
                                    <span>English website：http://en.ncac.gov.cn</span>
                                    <p>
                                      Copyright: The exclusive right owned by an author over his or her literary, artistic or scientific and technical work, and is a form of
                                      intangible property right. The main focus here is on copyright registration for computer software.
                                    </p>
                                  </div>
                                  <div class="copyinfo-info-code">
                                    <img src="../../assets/images/D/code.jpg" alt="" />
                                    <p>National Copyright Office WeChat</p>
                                  </div>
                                </div>
                                <div class="copy-introduce">
                                  <h1 class="copy-title">Example: Applying for computer software copyright</h1>
                                  <div class="txt-content">
                                    <p>Applicants can apply for computer software copyright registration by themselves or entrust an agency to register.</p>
                                    <p>Application information</p>
                                    <div class="number-list" style="margin-top: 0">
                                      <h1>
                                        (1) Registration department for copyright of computer software: The Software Registration Department of the Copyright Protection Center of
                                        China (CPCC).
                                      </h1>
                                    </div>
                                    <div class="number-list"><h1>(2) Processing period: Within 30 days of accepting the application.</h1></div>
                                    <div class="number-list">
                                      <h1>
                                        (3) Application process: Fill in web application form online → submit application documents→ registration department accepts application →
                                        review → registration certificate granted
                                      </h1>
                                    </div>
                                    <div class="number-list">
                                      <h1>(4) Application method:</h1>
                                      <p>
                                        Send in the application by registered mail or express mail to the software registration department of the Copyright Protection Center of China
                                        Mailing address: No. 302, 3rd Floor, Block A, Tianqiao Art Building, No. 1, Tianqiao South Street, Xicheng District, Beijing (Post Code:
                                        100050)
                                      </p>
                                    </div>
                                    <div class="number-list">
                                      <h1>(5) Methods of enquiry:</h1>
                                      <p>①Telephone enquiry on software registration</p>
                                      <p>Enquiry on software registration: 010—68003934, 64097080</p>
                                      <p>Enquiry on application approval process: 010—84195634</p>
                                      <p>Enquiry on mailing of certificates 010—68003887—5150</p>
                                      <p>Enquiry on amendment of software registration: 010—84195640</p>
                                      <p>②Telephone enquiry on software files and replacement of certificates</p>
                                      <p>Enquiry on application approval process: 010—68003887—7050</p>
                                      <p>③Complaints and suggestions on software registration: rjdj@ccopyright.com</p>
                                    </div>
                                    <div class="number-list">
                                      <h1>(6) Application materials</h1>
                                      <p>1. Application Form for Software Copyright Registration;</p>
                                      <p>2. Verification materials for software (programs, files);</p>
                                      <p>
                                        3. Personal identification documents of applicant (photocopy of passport), personal identification documents of contact person, and other
                                        relevant documents of proof.
                                      </p>
                                      <p>
                                        ① Personal identification documents of agent: For applications handled by agents, the photocopies of the personal identification documents of
                                        the agent must be submitted, and the agent’s appointed tasks, scope of authority, period of service and other relevant matters must be
                                        specified in the application form.
                                      </p>
                                      <p>② Personal identification documents of the applicant (stamp of official seal is required for organizations)</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="copy-introduce">
                                  <h1 class="copy-title">Process flow for registration of software copyright</h1>
                                  <img class="flow-img" width="100%" src="../../assets/images/D/img_01_m.jpg" alt="" />
                                </div>
                                <div class="copy-introduce">
                                  <h1 class="copy-title">Applicable laws and regulations:</h1>
                                  <div class="copy-statute-link">
                                    <el-col :xs="24" :sm="12" :md="12">
                                      <a href="http://www.ncac.gov.cn/chinacopyright/contents/590/21000.html">
                                        <div class="link-card left">
                                          <div class="txt-box">
                                            <p>Copyright Law of the People’s</p>
                                            <p>Republic of China (amended 2010)</p>
                                          </div>
                                          <div class="link-icon"><img src="../../assets/images/D/blue-link.png" alt="" /></div>
                                        </div>
                                      </a>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="12">
                                      <a href="http://www.ncac.gov.cn/chinacopyright/contents/479/17536.html">
                                        <div class="link-card right">
                                          <div class="txt-box">
                                            <p>Regulations for the</p>
                                            <p>Protection of Computer Software</p>
                                          </div>
                                          <div class="link-icon"><img src="../../assets/images/D/green-link.png" alt="" /></div>
                                        </div>
                                      </a>
                                    </el-col>
                                  </div>
                                </div>
                                <div class="question">
                                  <h1 class="copy-title">Q & A</h1>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">What is copyright ?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      Copyright: is the exclusive right owned by an author over his or her literary, artistic or scientific and technical work, and is a form of
                                      intangible property right.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">Which works are protected by law?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>Protected works include:</p>
                                      <p>(1) written works;</p>
                                      <p>(2) oral works;</p>
                                      <p>3) Music, drama, folk art, dance, acrobatic art works;</p>
                                      <p>(4) Fine arts and architectural works;</p>
                                      <p>(5) photographic works;</p>
                                      <p>(6) Movie works and works created in a manner similar to filmmaking;</p>
                                      <p>(7) Graphical works and model works such as engineering design drawings, product design drawings, maps, and schematic drawings;</p>
                                      <p>(8) computer software;</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">What are the main laws and regulations that protect authors' copyrights?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      "Criminal Law", "General Principles of Civil Law", "Copyright Law", "Regulations on the Implementation of Copyright Law", "Regulations on the
                                      Protection of Computer Software", "Regulations on the Management of Copyright Collectives", "Regulations on the Protection of Information
                                      Network Communication Rights", "Implementation of International Copyright Treaties" The Regulations and the Universal Copyright Convention and
                                      the Berne Convention that China has acceded to.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">How long is the protection period for the copyright of various works?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>(1) the term of protection of the author's rights of authorship, alteration, integrity and other personal rights shall not be restricted.</p>
                                      <p>
                                        (2) for a work of a citizen, the term of protection of the 14 property rights prescribed by the law of publication and copyright shall be the
                                        life of the author and fifty years after his death, expiring on December 31 of the fiftieth year after his death; In the case of collaborative
                                        works, the deadline is December 31 of the fiftieth year after the death of the last deceased author.
                                      </p>
                                      <p>
                                        (3) the legal person or other organization work, copyright (except the right of authorship) works created by the legal person or other
                                        organization, the right of publication and the copyright law protection of property rights of 14 for 50 years, ending on work December 31 of
                                        the fiftieth year after the first publication, but since the creation unpublished, fifty years after the completion of the law no longer
                                        protection.
                                      </p>
                                      <p>
                                        (4) films and work is created by virtue of the analogous method of film, photography, the right of publication and the copyright law
                                        protection of property rights of 14 for 50 years, ending on work December 31 of the fiftieth year after the first publication, but since the
                                        creation in fifty years after the completion of unpublished, no longer protect the copyright law.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">What is a job title? Who owns the copyright of the work of the job?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>
                                        The works created by citizens to complete the tasks of legal persons or other organizations are job titles.Copyright is owned by the author
                                        except in the following cases, but legal persons or other organizations have the right to use it preferentially within their business. Within
                                        two years of the completion of the work, the author may not permit the third person to use the work in the same manner as the unit does
                                        without the consent of the unit.The author has the right to authorize the job in one of the following circumstances: the other rights of the
                                        copyright are enjoyed by the legal person or other organization, and the legal person or other organization may award the author:
                                      </p>
                                      <p>
                                        (1) It is mainly an engineering design drawing, a product design drawing that is created by the legal or technical conditions of a legal
                                        person or other organization, and which is undertaken by a legal person or other organization.
                                      </p>
                                      <p>Jobs such as maps and computer software;</p>
                                      <p>(2) Jobs that are permitted by legal persons or other organizations as stipulated by laws, administrative regulations or contracts.</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">Where should copyright disputes be resolved?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>(1) Settlement: The parties have the intention to resolve themselves and can negotiate copyright disputes.</p>
                                      <p>
                                        (2) Mediation: The parties may entrust a third party (copyright office, copyright protection agency such as China Copyright Agency, copyright
                                        protection association, law firm, and natural person) to mediate copyright disputes.
                                      </p>
                                      <p>
                                        (3) Arbitration: The parties may apply to the arbitration institution for arbitration according to the written arbitration agreement reached
                                        or the arbitration clause in the copyright contract.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">What is computer software?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      Computer software refers to computer programs and related documentation. A computer program is a sequence of coded instructions that can
                                      implement a certain function, or a sequence of symbolic statements. A document is a text and diagram used to describe the content, composition,
                                      design, functional specifications, development, test results, and usage of the program, such as programming specifications, flowcharts, user
                                      manuals, and so on.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">Can the game be registered?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      A game work can be divided into two parts: game engine and game resources. The game resources include images, sounds, animations, etc. The game
                                      engine is the program code, and can apply for software copyright registration. In the game, animation, video, pictures, etc. belong to other
                                      works, and software copyright registration cannot be performed.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">Can the algorithm software be registered?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      The algorithm is a mathematical method. In software development, an algorithm implemented by a computer programming language is used, and its
                                      expression is protected by copyright and can be registered.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">Can foreign companies register in the country?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">Yes, they may register in accordance with the rules of copyright registration for computer software.</div>
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="tem-img"> -->
                              <!-- <img class="hidden-sm-and-down" width="100%" :src="essential21[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential21[1]" alt="" /> -->
                              <!-- </div> -->
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/02.png" alt class="enen" />
                                <img src="../../assets/images/D/trademark.png" alt class="item-i" />
                                <span class="item-txt-t">Trademark right</span>
                              </div>
                              <div class="tem-img" v-html="essential22">
                                <!-- <img class="hidden-sm-and-down" width="100%" :src="essential22[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential22[1]" alt="" /> -->
                                <div class="D2-02-02">
                                  <div class="top-info-box">
                                    <p>
                                      Relevant authority：Trademark Office (under the National Intellectual Property Administration)A trademark is the identifying mark for a
                                      business, which is therefore useful for keeping business activities in order.
                                    </p>
                                    <p>Chinese website of Trademark Office：http://sbj.saic.gov.cn</p>
                                    <p>English website of Trademark Office：http://home.saic.gov.cn/sbj/sbjEnglish</p>
                                  </div>
                                  <div class="eg-box">
                                    <h1 class="copy-title">Example: Apply for a registered trademark or service mark</h1>
                                    <div class="eg-txt-content">
                                      <div class="number-list">
                                        <h1>（1）Processing route：</h1>
                                        <h2>①The applicant submits an electronic application</h2>
                                        <p>The applicant may submit a trademark registration application via the online services system.</p>
                                        <p>Website of the online trademark services system: http://sbj.saic.gov.cn/wssq/</p>
                                        <h2>
                                          ②The applicant may submit the application at the trademark application counters set up by the local industry, commerce and market
                                          regulation department on behalf of the Trademark Office.Shenzhen trademark application counters
                                        </h2>
                                        <p>
                                          Address: Counter 25-27, Administrative Services East Hall, Civic Center, Fuzhong 3rd Road, Futian District, Shenzhen, Guangdong Province
                                        </p>
                                        <p>Telephone enquiry: 0755-88127758</p>
                                        <p>b.Go to the trademark registration hall of the Trademark Office.</p>
                                        <p>Trademark Office Trademark Registration Hall Office Address: No. 1, Chama South Street, Xicheng District, Beijing Post</p>
                                        <p>Code: 100055</p>
                                        <p>Office hours: 8:30-11:30 13:30-16:30</p>
                                        <p>support hotline：86-10-63218500</p>
                                        <p>③The applicant entrusts the trademark agency that has filed with the Trademark Office for processing.</p>
                                        <h1>（2）Processing process：</h1>
                                        <h2>
                                          ① The applicant directly goes through the online service system, log in to the online service system → select “Trademark Registration
                                          Application” → fill in the application form online → pay the trademark online Fees.
                                        </h2>
                                        <h2>② If the applicant handles it at the trademark registration hall, he can follow the steps below:</h2>
                                        <p>
                                          Query before the trademark registration application (non-essential procedure) → Prepare the application form → Submit the application form
                                          in the acceptance window → Confirm the submission of the application in the coding window → Pay the trademark fee at the payment window →
                                          Receive the receipt of the fee
                                        </p>
                                        <h2>③ Entrusted trademark agency to handle:</h2>
                                        <p>Applicants can voluntarily choose any trademark agency that has been filed with the Trademark Office.</p>
                                        <h1>（3）Pre-application inquiry (non-essential procedure)</h1>
                                        <p>
                                          it is recommended to submit the application after the judgment of the trademark inquiry result. Otherwise, if the application is rejected,
                                          the trademark registration fee and more waiting time will be lost.
                                        </p>
                                        <h1>（4）Application for trademark registration</h1>
                                        <p>To apply for trademark registration, the following documents should be submitted:</p>
                                        <p>①《Trademark Registration Application》</p>
                                        <p>
                                          a.Each application for trademark registration shall submit one copy of the “Application for Trademark Registration” to the Trademark
                                          Office.
                                        </p>
                                        <p>
                                          b.《The Trademark Registration Application can be submitted in paper or data message. If it is presented in paper form, it should be typed
                                          or printed. For details on specific data requirements, please refer to the relevant provisions of the “Online Application” section of the
                                          China Trademark Network.
                                        </p>
                                        <p>
                                          c.Where the applicant is a legal person or other organization, the official seal shall be affixed at the designated position of the
                                          application; if the applicant is a natural person, the applicant shall use a pen or a signature pen to sign and confirm at the designated
                                          place.
                                        </p>
                                        <p>
                                          d.When an applicant from Taiwan applies for a trademark registration application and requests priority from Taiwan, the application for
                                          trademark registration for the applicant in Taiwan shall be applied.
                                        </p>
                                        <p>
                                          e.For details on completing the “Application for Trademark Registration”, please refer to the instructions attached to the “Application
                                          for Trademark Registration”.
                                        </p>
                                        <h2>
                                          ② Photocopies of identification documents of the applicant (only one copy needs to be submitted if the same applicant is applying for
                                          multiple trademarks)
                                        </h2>
                                        <p>
                                          If the applicant is a Hong Kong, Macao or Taiwan legal person or other organization, it shall submit a copy of the registration
                                          certificate of the region or country. A copy of the registration certificate of the office and resident representative office of a foreign
                                          company in China cannot be used as a copy of the identity document. If the above documents are in a foreign language, they shall be
                                          accompanied by a Chinese translation; if they are not attached, they shall be deemed not to be submitted.
                                        </p>
                                        <p>
                                          b.If the applicant is a natural person of Hong Kong, Macao and Taiwan and handles it on its own, it shall submit a copy of the "Hong Kong
                                          and Macao Residents' Travel Permit to the Mainland", the "Taiwan Residents' Travel Permit" or the "Hong Kong, Macao and Taiwan Residents'
                                          Residence Permit" within the validity period (more than one year); the applicant is abroad. If the natural person handles it by himself,
                                          he shall submit a copy of the passport and the Alien Permanent Residence Permit issued by the public security department within the
                                          validity period (more than one year), the Alien Residence Permit or the Alien Residence Permit.
                                        </p>
                                        <h2>③ Trademark design</h2>
                                        <p>
                                          a.Each trademark registration application shall submit 1 trademark design. If a trademark registration is applied for a color combination
                                          or a coloring pattern, a coloring pattern shall be submitted; if no color is specified, a black and white pattern shall be submitted.
                                        </p>
                                        <p>
                                          b.The logo must be clear and easy to paste. It should be printed on clean and durable paper or replaced with photos. The length and width
                                          should be no more than 10 cm and no less than 5 cm. The trademark design should be affixed to the designated position in the Trademark
                                          Registration Application.
                                        </p>
                                        <p>
                                          c.Where a trademark registration is filed with a three-dimensional mark, it shall be stated in the application form, and the use of the
                                          trademark shall be stated in the “Trademark Description” column of the “Application for Trademark Registration”. The applicant should
                                          submit a pattern that is capable of determining the three-dimensional shape, which should contain at least a three-sided view.
                                        </p>
                                        <p>
                                          d.Where a trademark registration is applied for in a color combination, it shall be stated in the application form, and a textual
                                          description shall be given in the “Trademark Description” column of the “Application for Trademark Registration” to explain the use of the
                                          color code and the trademark. The trademark pattern should be a color block that indicates how the color is combined, or a graphic outline
                                          that indicates where the color is used. The graphic outline is not a trademark component and must be indicated by a dotted line and must
                                          not be indicated by a solid line.
                                        </p>
                                        <p>
                                          e.Where a registered trademark is applied for by a voice mark, it shall be declared in the application form, and the sound mark shall be
                                          described in the trademark design box, and the sound sample that meets the requirements shall be submitted, and in the “Trademark
                                          Description” column of the “Application for Trademark Registration” Explain how the trademark is used.
                                        </p>
                                        <p class="padding-left-2em">
                                          (a)Description of the sound mark. The sound applied for use as a trademark should be described and appended with a stippling or a
                                          notation; if it cannot be described in tabs or notation, it should be described in words.
                                        </p>
                                        <p class="padding-left-2em">(b)Sound sample requirements：</p>
                                        <p>
                                          ① When submitting a sound trademark registration application by paper, the audio file of the sound sample should be stored in a CD-ROM,
                                          and there should be only one audio file in the CD.
                                        </p>
                                        <p>
                                          If a voice trademark registration application is submitted through data message, the sound sample should be correctly uploaded as
                                          required.
                                        </p>
                                        <p>② The audio file of the sound sample should be less than 5MB in the format wav or mp3.</p>
                                        <p>③ The trademark description should be consistent with the sound sample.</p>
                                        <h1>
                                          （5）The various documents, supporting documents and evidentiary materials submitted by the applicant are in foreign languages and should
                                          be accompanied by a Chinese translation. If it is not attached, it shall be deemed that the document, supporting documents or evidential
                                          materials have not been submitted.
                                        </h1>
                                        <h1>（6）Trademark registration application correction procedure (not required)</h1>
                                        <p>
                                          If the application procedure is not complete or the application documents do not meet the requirements, and the information is required to
                                          be corrected, the application shall be corrected and returned to the Trademark Office within 30 days from the date of receipt of the
                                          notification.
                                        </p>
                                        <h1>（7）Substantive examination procedure for trademark registration application</h1>
                                        <p>
                                          The Trademark Office shall examine the application for trademark registration accepted in accordance with the relevant provisions of the
                                          Trademark Law. If it meets the requirements, it shall be initially examined and announced, and if it does not meet the requirements, it
                                          shall be rejected, and the applicant shall be notified in writing and the reasons shall be explained.
                                        </p>
                                        <h1>（8）Precautions</h1>
                                        <h2>
                                          (1) The Notice of Acceptance of Registration Application only indicates that the application for trademark registration has been accepted
                                          by the Trademark Office, and does not indicate that the application has been approved.
                                        </h2>
                                        <h2>
                                          (2) If the application for trademark registration is rejected, if the dissatisfaction is rejected, the applicant may apply to the
                                          Trademark Review and Adjudication Board for review within 15 days from the date of receipt of the notice of rejection.
                                        </h2>
                                        <h2>
                                          (3) If the trademark applied for registration is objected, if the applicant (ie, the objectee) is dissatisfied with the non-registration
                                          decision of the Trademark Office, it may apply to the Trademark Review and Adjudication Board for review within 15 days from the date of
                                          receipt of the notice.
                                        </h2>
                                        <h2>
                                          (4) A trademark is still an unregistered trademark after the application has been filed but has not yet been approved for registration,
                                          and must still be used as an unregistered trademark. If the use of the trademark infringes on the exclusive right of others' trademarks,
                                          it will not affect the investigation and punishment of the relevant industrial and commercial administration.
                                        </h2>
                                        <h2>
                                          (5) The registered trademark shall be valid for 10 years from the date of approval of the registration. If the registered trademark needs
                                          to continue to be used after the expiration of the validity period, the trademark registrant shall complete the renewal procedure within
                                          12 months before the expiration of the period. If the trademark registrant fails to handle it during this period, it may file it within
                                          the six-month extension period after the expiration, subject to the acceptance of the renewal registration fee. If the application for
                                          renewal is not submitted after the expiration of the extension period, the Trademark Office will cancel the registered trademark. If the
                                          original registrant wants to continue to own the exclusive right to use the trademark, it must re-apply for registration.
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="flowChart-box">
                                    <h1 class="copy-title">Process flow for trademark registration</h1>
                                    <img class="box-img" width="100%" src="../../assets/images/D/D2-2-2-img01-en.jpg" alt="" />
                                  </div>
                                  <div class="question">
                                    <h1 class="copy-title">Question answer</h1>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">Can foreigners and foreign companies apply for trademark registration by themselves?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        Where a foreigner or a foreign enterprise applies for trademark registration and handles other trademark matters in China, it shall entrust
                                        a trademark agency established according to law to handle it. However, foreigners or foreign companies with permanent residence or business
                                        offices in China can handle it themselves. The trademark agency filed by the Trademark Office of the State Intellectual Property Office can
                                        be found at http://wssq.saic.gov.cn:9080/tmsve/agentInfo_getAgentDljg.xhtml
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">We are a representative office of a company in Shenzhen. Can I apply for trademark registration?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">Representative offices and offices cannot apply for trademark registration in their own name.</div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What is the difference between self-management and entrusted trademark agency? Which way is faster?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        Either way, trademark registration applications require formal review and substantive examination, and the review requirements are the same.
                                        In the form of entrusting a trademark agency, it is necessary to sign a power of attorney with the trademark agency to handle the
                                        registration of the trademark. The application for the trademark registration document is also submitted directly by the trademark agency to
                                        the Trademark Office, and the applicant will not directly contact the Trademark Office.
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What is the Nice Classification? What is the Similar Sub-division of Goods and Services?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        The International Classification of Goods and Services for the Purposes of the Registration of Marks (Nice Classification) was established
                                        on the basis of an agreement (Nice Agreement) reached by the Nice Diplomatic Conference on June 15, 1957. Each Member State of the Nice
                                        Agreement is obliged to use the Nice Classification in the registration of a trademark and to indicate in the official documents and
                                        publications relating to the registration of the trademark the category number of the international classification in which the goods or
                                        services to which the registered trademark is located. Our country joined the Nice Agreement in 1994. The Similar Sub-division of Goods and
                                        Services is the requirement of the competent trademark authorities for the purpose of trademark search, examination and management. It
                                        summarizes the practical work experience over the years and extensively solicits opinions from various departments to make certain
                                        connections and misunderstandings. The goods or services are combined and compiled. The Similar Sub-division of Goods and Services can be
                                        used as a reference for trademark examiners, trademark attorneys and trademark registration applicants to judge similar goods or services,
                                        or as a reference for administrative agencies and judicial organs to judge similar goods or services when dealing with trademark cases.
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What are the classification principles for goods?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        The general classification principles for goods and services are given in the International Classification of Goods and Services for the
                                        Purposes of the Registration of Marks (Nice Classification). Applicants can check it out. In general, the names of the goods or services
                                        listed in the category headings in principle constitute the general names of the scope of the goods or services. So to determine the
                                        classification of each item or service, you have to look at the alphabetical list.
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What are the classification principles for services?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        he general classification principles for goods and services are given in the International Classification of Goods and Services for the
                                        Purposes of the Registration of Marks (Nice Classification). Applicants can check it out. In general, the names of the goods or services
                                        listed in the category headings in principle constitute the general names of the scope of the goods or services. So to determine the
                                        classification of each item or service, you have to look at the alphabetical list.
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">Can I use the trademark after I receive the acceptance notice?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        The acceptance notice only indicates that the trademark registration application has been accepted by the Trademark Office and does not
                                        indicate that the applied trademark has been approved for registration. A trademark is still an unregistered trademark after the application
                                        has been filed but has not yet been approved for registration, and must still be used as an unregistered trademark. If the use of the
                                        trademark infringes on the exclusive right of others' trademarks, it will not affect the investigation and punishment of the relevant
                                        industrial and commercial administration.
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What are the circumstances of inadmissibility?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <p>In general, the main cases of non-acceptance of trademark registration applications include (but are not limited to) the following:</p>
                                        <p>·Failure to use the correct application form as required; modify the application form without authorization.</p>
                                        <p>
                                          ·The application does not use Chinese; the various documents, supporting documents and evidentiary materials submitted are in foreign
                                          languages, and the Chinese translation documents are not attached and the official seal of the applicant, agency or translation company is
                                          attached.
                                        </p>
                                        <p>
                                          ·The name of the applicant, the stamp or signature stamped on the application form, and the copy of the attached identification document
                                          are inconsistent.
                                        </p>
                                        <p>·The trademark design does not comply with the provisions of Article 13 of the Implementing Regulations of the Trademark Law.</p>
                                        <p>·The overseas notarization does not include the certification documents at the same time.</p>
                                        <p>
                                          ·No fees have been paid to the Trademark Office.
                                          <strong></strong>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/03.png" alt class="enen" />
                                <img src="../../assets/images/D/patent.png" alt class="item-i" />
                                <span class="item-txt-t">Patent right</span>
                              </div>
                              <div class="tem-img" v-html="essential23">
                                <!-- <img class="hidden-sm-and-down" width="100%" :src="essential23[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential23[1]" alt="" /> -->
                                <div class="D2-02-02">
                                  <div class="top-info-box">
                                    <p>Include inventions, utility models and industrial designs</p>
                                    <p>Relevant authority: National Intellectual Property Administration</p>
                                    <p>Chinese website：http://www.sipo.gov.cn/zhfwpt/zlsqzn_pt/zlfssxzjsczn/index.htm</p>
                                    <p>English website：http://english.cnipa.gov.cn/lawpolicy/index.htm</p>
                                  </div>
                                  <div class="eg-box">
                                    <h1 class="copy-title">Example: Patent application</h1>
                                    <div class="eg-txt-content">
                                      <div class="number-list">
                                        <h1>(1) Application route</h1>
                                        <h2>
                                          ①For an electronic application, the applicant will need to first complete user registration for electronic application, and then submit
                                          the application and other documents to the Patent Office through its electronic patent application system.
                                        </h2>
                                        <h2>②Application in writing.</h2>
                                        <p>
                                          The application and other documents need to be submitted in person to the application counters of the Patent Office or sent to the
                                          “Application Counter of the Patent Office of the National Intellectual Property Administration”, or alternatively they can be submitted in
                                          person to the application counters of the representative office of the Patent Office or sent to the “Representative Office of the Patent
                                          Office of the National Intellectual Property Administration”.
                                        </p>
                                        <p>Application Counter of the Patent Office of the China National Intellectual Property Administration</p>
                                        <p>General line: 010-62083114</p>
                                        <p>Customer service: 010-62085588/5599、010-62356655</p>
                                        <p>Enquiry on patent application review:010-62801799</p>
                                        <p>Enquiry on patent services: 010-62086383</p>
                                        <p>Patent services email: cpquery@sipo.gov.cn</p>
                                        <p>Enquiry on patent literature: 010-62083246/3238</p>
                                        <p>Address: No. 6, West Tucheng Road, Qimen Bridge, Haidian District, Beijing</p>
                                        <p>Shenzhen Representative Office of the Patent Office of the National Intellectual Property Administration</p>
                                        <p>Tel：0755-26617303</p>
                                        <p>Fax：0755-26617304</p>
                                        <p>Post code：518057</p>
                                        <p>
                                          Address: 6th Floor, Block C, Building 4, Shenzhen Software Industry Base, Xuefu Road, Gaoxin South District, Nanshan District, Shenzhen,
                                          Guangdong Province
                                        </p>
                                        <p>Website：www.szipr.org</p>
                                        <p>Bank： China Everbright Bank, Shenzhen Hi-Tech Park branch</p>
                                        <p>Account name: Shenzhen Representative Office of the Patent Office of the National Intellectual Property Administration</p>
                                        <p>Account number: 39020188000058725</p>
                                        <h1>(2) Application documents required to be submitted for a patent application include:</h1>
                                        <h2>
                                          ①When applying for patents for inventions, the application documents shall include: The invention patent request, abstract (submit
                                          drawings of abstract if necessary), patent claims, and specification (submit drawings of specification if necessary)
                                        </h2>
                                        <h2>
                                          ②When applying for patents for utility models, the application documents shall include: The utility model patent request, abstract,
                                          drawings of abstract, patent claims, specification, drawings of specification.
                                        </h2>
                                        <h2>
                                          ③When applying for patents for industrial designs, the application documents shall include: The industrial design patent request, images
                                          or photographs (if protection of colors is sought, the images or photographs must be provided in color), and a brief description of the
                                          industrial design.
                                        </h2>
                                        <h1>
                                          <span>(3) Application documents use a unified form</span>
                                          <p style="padding-left: 0.76rem;">Also note that application documents must use the standard forms of the Patent Office.</p>
                                        </h1>
                                        <p>The forms can be obtained in the following manner:</p>
                                        <p>1.Download from the website of the National Intellectual Property Administrationhttp://www.cnipa.gov.cn/bgxz/;</p>
                                        <p>2.Obtain a copy at the enquiry counter of the Application Submission Hall of the Patent Office;</p>
                                        <p>3.Obtain a copy at the Shenzhen Representative Office of the Patent Office.</p>
                                        <p>
                                          The paper used for application documents must be of the same quality as photocopy paper. There must be no unnecessary wordings, markings,
                                          boxes or lines on the paper. The paper used for all documents must be A4 in size (210 mm X 297 mm). The paper for application documents
                                          must be used on one side only and in portrait orientation. The words must be arranged from left to right, with a 25 mm margin on the left
                                          and at the top, and a 15 mm margin on the right and at the bottom.
                                        </p>
                                        <h1>(4) The correct order of documents submitted should also be followed:</h1>
                                        <p>
                                          Patent application documents for inventions or utility models (in the following order): Request, abstract, drawings of abstract,
                                          specification (including any amino acid or nucleotide sequence listings), and drawings of specification.
                                        </p>
                                        <p>
                                          Application documents for industrial designs (in the following manner): Request, images or photographs, and brief description. All parts
                                          of the application documents must be marked with page numbers in sequence in Arabic numerals.
                                        </p>
                                        <h1>(5) Note the following text requirements for the application documents:</h1>
                                        <p>
                                          All parts of the application documents shall be in Chinese. Where the names of foreign persons, places or technical terms do not have a
                                          standardized Chinese translation, the translated names shall be followed by their original names in parentheses.
                                        </p>
                                        <p>
                                          The application documents shall be typed or printed in the Songti, Fangsongti or Kaiti font in black, with a font height of 3.5 - 4.5 mm,
                                          and a spacing of 2.5 - 3.5 mm. If drawings are included in the application documents, their lines should be even and clear, without any
                                          corrective markings. Project blueprints shall not be used as drawings.
                                        </p>
                                        <h1>(6) Documents of proof</h1>
                                        <p>
                                          Documents of proof required to be submitted in the patent application process must be issued by the relevant supervising departments or
                                          signed by the party concerned. All documents of proof must be original. Any photocopy must be notarized, or certified by the supervising
                                          department that issued the document with the stamp of its official seal (except where the original documents are filed with the Patent
                                          Office). Documents of proof that are in a foreign language must be accompanied by their Chinese translations.
                                        </p>
                                        <h1>(7) Signature or seal</h1>
                                        <p>
                                          All patent application or other documents submitted to the Patent Office must be signed or stamped with a seal in accordance with
                                          requirements. Any application not handled by a patent agent must be signed by or stamped with the seal of the applicant (or patent
                                          holder), other stakeholders or their representatives. Any application concerning joint rights of the patent must be signed by or stamped
                                          with the seals of all rights holders. If a patent agent has been appointed, the application shall be stamped with the seal of the agency,
                                          and if necessary the signature or seal stamps of the applicant (or patent holder), other stakeholders or their representatives shall also
                                          be included.
                                        </p>
                                        <h1>(8) Simultaneous applications</h1>
                                        <p>
                                          If an applicant files for both the utility model and patent of an invention on the same day, this must be specified respectively during
                                          the application.
                                        </p>
                                        <h1>(9) Patent application must comply with the unity of invention</h1>
                                        <p>One invention or utility model application can only relate to one invention or utility model.</p>
                                        <h1>(10) Appointment of patent agents</h1>
                                        <p>
                                          Any foreigner, foreign enterprise or foreign organization with no regular domicile or business address in China, who is filing for a
                                          patent in China or filing as a first-named applicant jointly with another applicant from China for a patent, shall appoint a legally
                                          established patent agency to handle the application.
                                        </p>
                                        <h1>(11) Acknowledgement of patent application</h1>
                                        <p>
                                          After receiving a patent application which fulfills the submission requirements, the Patent Office or its representative office shall
                                          issue a notice of acknowledgement to the applicant confirming the application date and providing an application number.If application
                                          documents have been submitted to the Application Submission Counter of the Patent Office, the notice of acknowledgement from the Patent
                                          Office will normally be received within around one month. If the notice has not been received after more than a month, the applicant
                                          should promptly enquire with the Patent Office.
                                        </p>
                                        <h1>(12) Determination of application date</h1>
                                        <p>
                                          For patent application and documents submitted to the Patent Office electronically, the submission date shall be the date the electronic
                                          documents are received by the Patent Office’s electronic patent application system.For patent applications submitted to the Application
                                          Counter of the Patent Office or representative office counters, the application date shall be their date of receipt. For patent
                                          applications delivered by mail to the Application Counter of the Patent Office or representative office counters, the post mark date on
                                          the envelope shall be the application date.
                                        </p>
                                        <h1>(13) Payment period for application fee</h1>
                                        <p>
                                          The applicant shall within two months of the application date or within 15 days of receiving the notice of acknowledgement complete
                                          payment of the application fee. The corresponding application number and relevant payment details must be provided during the process of
                                          payment.
                                        </p>
                                        <h1>(14) Payment methods</h1>
                                        <h2>
                                          ①Electronic applicants may log on to the electronic application website (http://cponline.cnipa.gov.cn/) and pay the patent fee online.
                                        </h2>
                                        <h2>②Pay directly to the Patent Office or its representative office.</h2>
                                        <h2>
                                          ③Remit the payment through banks or post offices. For remittance through banks or post offices, the application number (or patent number)
                                          or name of payment item (or its abbreviation) must be specified correctly in the remittance slip.Bank transfer:
                                        </h2>
                                        <p>Bank：CITIC Bank, Beijing Zhichun Road branch</p>
                                        <p>Account name：Patent Office of the National Intellectual Property Administration of the People’s Republic of China</p>
                                        <p>Account number: 7111710182600166032</p>
                                        <p>Post office remittance：</p>
                                        <p>Name of payment recipient: Payment Department of the Patent Office of the National Intellectual Property Administration</p>
                                        <p>Business client no.: 110000860 (can replace post code)</p>
                                        <p>Post Code: No. 6, Xitucheng Road, Jimen Bridge, Haidian District, Beijing (100088)</p>
                                        <h1>(15) Confidentiality review on patent applications to be filed abroad</h1>
                                        <p>
                                          If any organization or individual has completed an invention or utility model in China but intends to file for a patent in another country
                                          or submit an international patent application to the patent authority of the country, it must first request for a confidentiality
                                          examination by the Patent Office on the patent application to be filed abroad. After the confidentiality examination, if it is found that
                                          the patent involves matters relating to national security or other significant interests which must be kept confidential, the organization
                                          or individual must not file for the patent in any foreign country pertaining to the invention or utility model in question.
                                        </p>
                                        <h2>There are three ways to file a confidentiality review request before applying for a patent in a foreign country:</h2>
                                        <h2>
                                          ①Request for a confidentiality examination through the submission of the technical scheme. For requests made in such a manner, the
                                          applicant shall submit a request for confidentiality examination on patents to be filed abroad and the specification of the technical
                                          scheme, and submit the documents in writing in person to the application counters of the Patent Office or mail them to the “Application
                                          Counter of the Patent Office of the National Intellectual Property Administration”.
                                        </h2>
                                        <h2>
                                          ②Make the request for confidentiality examination when or after filing the patent in China. For requests made in such a manner, the
                                          applicant shall submit a request for confidentiality examination on patents to be filed abroad
                                        </h2>
                                        <h2>
                                          ③If an international patent application has been made to the Patent Office, a request for confidentiality examination shall be deemed as
                                          having been made, and a request for confidentiality examination on patents to be filed abroad need not be submitted separately.
                                        </h2>
                                        <h2>
                                          The Guidelines for Services Related to Confidentiality Examinations on Patents to be Filed Abroad can be found at
                                          http://www.cnipa.gov.cn/docs/20180206152953119132.pdf.
                                        </h2>
                                        <h1>(16) Important notes regarding the submission of application documents</h1>
                                        <h2>
                                          ①The applicant must keep a copy of all documents submitted to the Patent Office, to ensure the consistency of details in Chinese during
                                          the review process, and which can be referred to when replying to reviewer comments.
                                        </h2>
                                        <h2>
                                          ②Application documents shall be sent in by registered mail. If registered mail is not possible, express mail shall be used. Application
                                          documents must not be mailed in parcels. In addition to the address of the Patent Office or its representative office (including the post
                                          code), the registered mail should also specify the wordings “Patent Documents” and “To the Application Counter of the Patent Office of the
                                          National Intellectual Property Administration” or “To the XX Representative Office of the Patent Office of the National Intellectual
                                          Property Administration”. For application documents submitted by express mail, the application date shall be the actual date of receipt by
                                          the Application Counter of the Patent Office or the representative office. One registered mail should only contain documents for the same
                                          application. After mailing the documents, the applicant should keep the acknowledgement of receipt.
                                        </h2>
                                        <h2>
                                          ③The Patent Office does not accept samples, prototypes or models when receiving patent applications. During the process of review, if the
                                          applicant has been requested by the reviewer to submit a sample or model, and is submitting at the Application Counter of the Patent
                                          Office, the applicant shall present the notice of comments of review. If the items are being mailed, the wordings “Submission of models as
                                          requested by reviewer XXX (name)” shall be included.
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="flowChart-box">
                                    <h1 class="copy-title">Patent application flow chart</h1>
                                    <img width="100%" src="../../assets/images/D/D2-2-3-img01-en.jpg" alt="" class="box-img" />
                                  </div>
                                  <div class="question">
                                    <h1 class="copy-title">Question answer</h1>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What is a patent for invention?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        The invention referred to in the patent law refers to a new technical solution proposed for the product, method or improvement thereof.
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What is a design patent?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <p>
                                          esign refers to the design of the appearance of the product. It is completely different from the invention or utility model, that is, the
                                          design is not a technical solution. The design patent shall meet the following requirements:
                                        </p>
                                        <p>（1）Refers to the design of shapes, patterns or combinations thereof and combinations of colors and shapes and patterns;</p>
                                        <p>（2）Must be the design of the appearance of the product；</p>
                                        <p>（3）Must be beautiful；</p>
                                        <p>（4）Must be suitable for industrial applications.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What is a utility model? Which inventions can apply for utility model patents?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        The utility model referred to in the Patent Law refers to a new technical solution suitable for practical use proposed for the shape,
                                        structure or combination of the products. The utility model differs from the invention in that: first, the utility model is limited to a
                                        product having a certain shape, and cannot be a method or a product having no fixed shape; secondly, the inventive requirement of the
                                        utility model is compared with the invention. low.
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">
                                        After applying for a patent, it is illegal to print the application number into a patent number on the product packaging before it is
                                        approved.
                                      </div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        Illegal. Article 17 of the Patent Law stipulates that the patentee has the right to mark the patent mark and patent number on the patented
                                        product or the package of the product. It should be pointed out that in accordance with the provisions of this Article, after the patent
                                        application is filed, before the authorization, the applicant may not mark the application number as its patent number on its product or
                                        packaging because it has not yet obtained the patent right.
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">Can the applicant (individual) and the inventor or designer be different people?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">The applicant (individual) and the inventor or designer can be different people.</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </el-collapse-item>
                          </el-collapse>
                        </el-tab-pane>
                      </el-tabs>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane class="cyyy" label="Tax & Law" name="3">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="60%" src="../../assets/images/en/A/TL.png" alt="" />
                    <img class="hidden-md-and-up" width="55%" src="../../assets/images/en/A/TL-m.png" alt="" />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-collapse class="mian-left-l">
                        <div class="title-box">
                          <div>
                            <div class="text">Organizer:</div>
                            <div class="text">The Shenzhen Taxation Bureau under the State Taxation Administration</div>
                          </div>
                        </div>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/01.png" alt class="enen" />
                            <img src="../../assets/images/D/taxes.png" alt class="item-i" />
                            <span class="item-txt-t">Taxes and tax rates</span>
                          </div>
                          <div class="tem-img" v-html="taxLaw1">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="taxLaw1[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="taxLaw1[1]" alt="" /> -->
                            <div class="tab">
                              <div class="el-row">
                                <div class="el-col el-col-6"><div class="tab-header-td">Tax item</div></div>
                                <div class="el-col el-col-6"><div class="tab-header-td">Tax rates</div></div>
                                <div class="el-col el-col-12"><div class="tab-header-td">Important details</div></div>
                              </div>
                              <div class="el-row">
                                <div class="tab-content-tr">
                                  <div class="el-col el-col-6">
                                    <div class="tab-content-td"><div class="box">Corporat- eincome tax</div></div>
                                  </div>
                                  <div class="el-col el-col-6">
                                    <div class="tab-content-td"><div class="box">5%-25%</div></div>
                                  </div>
                                  <div class="el-col el-col-12">
                                    <div class="tab-content-td">
                                      <div class="box">
                                        <p>Basic tax rate: 25%</p>
                                        <p>Small low-profit enterprises</p>
                                        <p>Effective tax rate for annual taxable income of RMB 0 to 1 million, : 5%</p>
                                        <p>Effective tax rate for annual taxable income of RMB 1 to 3 million: 10%</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="el-row">
                                <div class="tab-content-tr">
                                  <div class="el-col el-col-6">
                                    <div class="tab-content-td"><div class="box">Persona- lincome tax</div></div>
                                  </div>
                                  <div class="el-col el-col-6">
                                    <div class="tab-content-td"><div class="box">3%-45%</div></div>
                                  </div>
                                  <div class="el-col el-col-12">
                                    <div class="tab-content-td">
                                      <div class="box">
                                        <p>
                                          Personal income tax is calculated at a progressive tax rate of 3% - 45% over the individual’s combined income (wages, salary, remuneration
                                          for labor, author’s remuneration, franchise royalties, etc.)
                                        </p>
                                        <p>
                                          Note: The amount of personal income to be paid is based on theannual income of the taxable year deducted by RMB 60,000 (RMB 5000 for each
                                          month) and other special deductions, additional special deductions, and deductions prescribed by law.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="el-row">
                                <div class="tab-content-tr">
                                  <div class="el-col el-col-6">
                                    <div class="tab-content-td"><div class="box">Value-added tax</div></div>
                                  </div>
                                  <div class="el-col el-col-6">
                                    <div class="tab-content-td"><div class="box">3%-16%</div></div>
                                  </div>
                                  <div class="el-col el-col-12">
                                    <div class="tab-content-td">
                                      <div class="box">
                                        <p>Basic VAT rate for small-scale taxpayers: 3%</p>
                                        <p>Basic VAT rate for general taxpayers: 13%</p>
                                        <p>Deduction rate for purchased agricultural products is 9%</p>
                                        <p>Rate for sales and imported goods is 13%</p>
                                        <p>VAT is exempt for enterprises with monthly sales lower than RMB 100,000.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/02.png" alt class="enen" />
                            <img src="../../assets/images/D/concr.png" alt class="item-i" />
                            <span class="item-txt-t">Tax knowledge</span>
                          </div>
                          <div class="tem-img" v-html="taxLaw2">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="taxLaw2[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="taxLaw2[1]" alt="" /> -->
                            <div class="cnts-item i-five">
                              <div>
                                <h1>The taxable year：</h1>
                                <p>From January 1 to December 31 of a calendar year</p>
                              </div>
                              <div>
                                <h1>Tax payment method：</h1>
                                <p>1.By direct deduction from the company’s bank account through linking the company’s bank account to the tax authorities.</p>
                                <p>2.By payment through WeChat.</p>
                              </div>
                              <div>
                                <h1>Tax filing schedule：</h1>
                                <p>1.Personal income tax needs to be declared by the 15th of each month. Generally, salaries are paid monthly.</p>
                                <p>
                                  2.The VAT, corporate income tax and other relevant taxes of the previous quarter need to be declared to the tax authorities by April 15, July 15,
                                  October 15 and January 15 each year.
                                </p>
                                <p>3.The annual financial and audit reports need to be submitted to the tax authorities by May 31 each year.</p>
                              </div>
                              <div class="cnt2">
                                <h1>Tips:</h1>
                                <p>
                                  1.The legal representative/finance manager/tax executive of your company needs to register himself or herself at the tax department by means of
                                  real-name verification, as well as his or her mobile number in China. Otherwise, you will not be able to process your taxes. The registration
                                  should be made after the company has applied for a business license and made an official seal;
                                </p>
                                <p>
                                  2.Comply with the rules when using invoices. For a sale of goods, an invoice needs to be issued to the buyer. If an invoice is not issued in
                                  accordance to the details, the tax department may suspect tax evasion, for which you may be subject to penalty;
                                </p>
                                <p>
                                  3.The company’s operating income must be deposited in the company’s general bank account, and not in the personal bank account of the legal
                                  representative;
                                </p>
                                <p>
                                  4.Ask for invoices from drivers or shops when travelling in cabs or dining at restaurants during business trips. Invoices are the legal proof of
                                  your activities during the business trip.
                                </p>
                              </div>
                              <div>
                                <h1>Question answer</h1>
                                <ul>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">What is the difference between registered capital and paid-in capital?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          The registered capital of a company is raised at the time of its establishment, and is stated clearly in the company charter and
                                          registered with the company registration authorities. It is the amount of capital subscribed to by shareholders. Paid-in capital is the
                                          amount of capital contribution actually received from the shareholders when the company is established. It is the amount of actual capital
                                          held by the company.
                                          <br />
                                          As the subscribed shares of a company may either be paid for in a lump sum or in installments, the paid-in capital of a company may
                                          sometimes be lower than its registered capital. In any case, a company’s registered capital and paid-in capital should ultimately be the
                                          same.
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">Is it necessary to pay stamp duty when the paid-in capital is in place?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">It needs to be paid according to the specific amount of paid-in capital, and the tax rate is five ten thousandths.</div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">Does the company need to go to the tax bureau to register for the office?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          Yes. You will need to complete a tax registration at your local tax department within 30 days of the purchase. After the registration is
                                          complete, you will need to pay a property tax. Any default in payment will result in a fine and an increased tax amount.
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">The invoice is lost, what is the impact?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">Losing an invoice will incur a fine, the amount of which is determined by the relevant tax department.</div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">
                                        The Shenzhen Taxation Bureau has implemented online ticket purchase. Can the online ticketing address be filled in with an address other
                                        than Shenzhen?
                                      </div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          No. The measures for tax invoices do not allow for the delivery and use of invoices outside the local area. Therefore, you are not allowed
                                          to deliver blank invoices purchased online to addresses outside Shenzhen, and taxpayers are not allowed to use the blank invoices in a
                                          different place.
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/03.png" alt class="enen" />
                            <img src="../../assets/images/D/legal.png" alt class="item-i" />
                            <span class="item-txt-t">Legal Notice</span>
                          </div>
                          <div class="tem-img" v-html="taxLaw3">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="taxLaw3[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="taxLaw3[1]" alt="" /> -->
                            <div class="cnts-item i-six">
                              <div>
                                <h1>
                                  Tax and financial risks
                                  <span>(Organizer: The Shenzhen Taxation Bureau under the State Taxation Administration.)</span>
                                </h1>
                                <p>
                                  Since the financial and tax regulations of any two countries are different, naturally the tax policies in China will differ from those of other
                                  countries. For instance, tax declarations are made monthly, quarterly and annually. Failure to declare in time will result in you being
                                  blacklisted and paying a fine and an increased tax amount.
                                </p>
                              </div>
                              <div>
                                <h1>
                                  Employment risk
                                  <span>(Organizer: the Shenzhen Human Resources and Social Security Bureau. )</span>
                                </h1>
                                <p>
                                  If you hire Chinese, you need to know the labor laws in China. If you hire foreigners, you should pay attention to the timely processing of work
                                  visa and other relevant procedures. When recruiting employees, special attention should be paid to the recruitment registration, legal review,
                                  labor contract, confidentiality agreement, non-competition clause setting and the need to buy social insurance and provident fund for employees.
                                </p>
                              </div>
                              <div>
                                <h1>
                                  Business risk
                                  <span>(Organizer: the Ministry of Commerce of the People’s Republic of China.)</span>
                                </h1>
                                <p>
                                  Foreign investors in China must comply with the Special Administrative Measures (Negative List) for the Access of Foreign Investment and the
                                  Negative List for Market Access. Any business operation that goes beyond the scope of these measures will be investigated by the government and be
                                  subject to penalty.
                                </p>
                              </div>
                              <div class="cnt2">
                                <div class="left"><img src="../../assets/images/D/six-code1.png" alt="" /></div>
                                <div class="right">
                                  <p>Development and Reform Commission Order No. 19 of 2018</p>
                                  <p>Special Management Measures for Foreign Investment</p>
                                  <p>Access in the Free Trade Zone (Negative List) (2018 edition)</p>
                                </div>
                              </div>
                              <div>
                                <h1>Question answer</h1>
                                <ul>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">
                                        Before the establishment of the enterprise, the company's annual report must be completed before June 30 of the next year. Does it not
                                        affect the company without publicity?
                                      </div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          Enterprises that have not disclosed their annual reports as required will be placed by the department for industry and commerce on a list
                                          of irregularly operated enterprises and fined accordingly. Failure to conform to this requirement for more than three years will result in
                                          the enterprise being included permanently by the department for industry and commerce on the “blacklist” of seriously non-compliant
                                          enterprises.
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">
                                        In addition to the annual report publicity, the same need to be settled in the tax bureau system, and the impact of not reporting on the
                                        enterprise on time is?
                                      </div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          An enterprise also needs to complete the settlement and payment of tax with the taxation bureau before May 31 of the year following its
                                          establishment. Any enterprise failing to settle and pay taxes within the stipulated period or submit complete documents may be fined and
                                          charged a higher tax amount by the tax authorities.
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">Is it necessary to issue an audit report?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          Audit reports can be issued by the enterprise according to its needs. An audit report can be issued if it is needed by a third-party
                                          during tendering and bidding processes, or to instill confidence in investors who are not involved in the actual operation of the company,
                                          or enable the management to have a better understanding of the company’s finances.
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">How to buy social insurance for company employees?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          <p>
                                            There are two ways to purchase social insurance for your employees. You may apply to the Human Resources and Social Security Bureau of
                                            Shenzhen Municipality for a company social insurance account, after obtaining an account opening permit. Alternatively, you may ask your
                                            HR personnel to complete the relevant procedures at the local social insurance authority by bringing the employment contracts and other
                                            relevant documents. To enquire about the list of documents required, you may call the Chinese-language service hotline 12333.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/04.png" alt class="enen" />
                            <img src="../../assets/images/D/suggest.png" alt class="item-i" />
                            <span class="item-txt-t">Financial and legal suggestions</span>
                          </div>
                          <div class="tem-img" v-html="taxLaw4">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="taxLaw4[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="taxLaw4[1]" alt="" /> -->
                            <div class="cnts-item i-seven">
                              <h1>Advantages and disadvantages of service outsourcing and hiring an internal team</h1>
                              <div class="tab">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">Approach</div></el-col>
                                  <el-col :span="6"><div class="tab-header-td">Recommendation</div></el-col>
                                  <el-col :span="12"><div class="tab-header-td">Pros and Cons</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Finance / legal services outsourcing</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>During the early stages of a company, when the team is small and there is still limited understanding of the Chinese market, it may be a good idea to consider outsourcing your financial and tax/legal work.</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <div class="left">Pros:</div>
                                          <div class="right">
                                            <p>
                                              (1) Highly professional: Tax agencies are professional in their work and promptly handle tax declarations, thus significantly reducing the risk of penalty for late declarations and damaging the company’s credibility. Engaging a legal services organization will also give you access to the quality services of professional legal teams.
                                            </p>
                                            <p>(2) Reduces operational costs.</p>
                                          </div>
                                        </div>
                                        <div class="box">
                                          <div class="left">Cons:</div>
                                          <div class="right"><p>Risks of disclosure of trade information and lack of communication efficiency.</p></div>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Hiring an internal finance / legal team</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>After a company has reached a certain size, and its continued expansion requires the prompt and immediate communication and interaction of finance/legal personnel, it may be advisable to hire an internal finance/legal team.</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <div class="left">Pros:</div>
                                          <div class="right"><p>More secure:Prompt and immediate communication and interaction helps expedite internal financial processes.</p></div>
                                        </div>
                                        <div class="box">
                                          <div class="left">Cons:</div>
                                          <div class="right"><p>Higher costs: More costs need to be incurred for employees’ salaries, social insurance and provident fund contributions, as well as benefits.</p></div>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                              <h1>List of well-known accounting firms in Shenzhen can provide financial and taxation outsourcing services</h1>
                              <div class="tab b2">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">Name</div></el-col>
                                  <el-col :span="6"><div class="tab-header-td">Address</div></el-col>
                                  <el-col :span="12"><div class="tab-header-td">Website</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>PricewaterhouseCoopers Shenzhen Office</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>34th Floor, Tower A, Kingkey 100, 5016 Shennan East Road, Luohu District, Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code1.png" alt="" /></div>
                                        <div class="box"><p>www.pwccn.com/en.html</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Deloittet Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>13th Floor, China Resources Building,5001 Shennan East Road, Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code2.png" alt="" /></div>
                                        <div class="box"><p>www.deloitte.com/cn/en.html</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Ernst & Young Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>21st Floor, China Resources Building, No. 5001 Shennan East Road, Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code3.png" alt="" /></div>
                                        <div class="box"><p>www.ey.com/en_gl/</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>KPMG Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>15th Floor, China Resources Building, No. 5001 Shennan East Road, Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code4.png" alt="" /></div>
                                        <div class="box"><p>home.kpmg/cn/zh/home.html</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>BDO Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>16th Floor, Building A, Shenzhen Investment International Business Center, No. 1061, Xiangmei Road, Futian District, Shenzhen </p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code5.png" alt="" /></div>
                                        <div class="box"><p>www.bdo.com.cn/en-gb/microsites/english/home</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                              <h1>A list of well-known law firms in Shenzhen can provide legal business outsourcing services</h1>
                              <div class="tab b2">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">Name of Law Firm</div></el-col>
                                  <el-col :span="6"><div class="tab-header-td">Contact Details</div></el-col>
                                  <el-col :span="12"><div class="tab-header-td">Website</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Beijing Yingke (Shenzhen) Law Firm</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>3rd Floor, Tower B, Rongchao Business Center, No. 6003, Yitian Road, Futian District, Shenzhen, Guangdong Province</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code6.png" alt="" /></div>
                                        <div class="box"><p>www.yingkelawyer.com</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Guangdong Sincere Qianhai Law Offices</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Building 8 Qianhai Enterprise Dream Park，No.63 Qianwan 1st Road，Qianhai Shenzhen-Hongkong Modern Service Industry Cooperation Zone, Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code7.png" alt="" /></div>
                                        <div class="box"><p>www.qianhailaw.cn/services</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>China Commercial Lam Lee Lai (Qianhai) Law Firm</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Unit B, First Floor, Building 3, Enterprise Dream ParkQianhai Shenzhen-Hongkong Modern Service Industry Cooperation Zone, Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code8.png" alt="" /></div>
                                        <div class="box"><p>www.hs-lll.cn/en</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Global Law Office</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Units B/C, 26th Floor, Tower 5, Dachong International Center, No. 39 Tonggu Road, Nanshan District, Shenzhen </p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code9.png" alt="" /></div>
                                        <div class="box"><p>www.glo.com.cn/en/</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>Dentons Shenzhen </p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>3rd & 4th Floor, Block A, Shenzhen International Innovation Center, No.1006, Shennan Boulevard, Futian District, Shenzhen</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code10.png" alt="" /></div>
                                        <div class="box"><p>shenzhen.dachenglaw.com</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane class="cyyy" label="Visa & Immigrant" name="4">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/VISA.png" alt="" />
                    <img class="hidden-md-and-up" width="80%" src="../../assets/images/en/A/VISA-m.png" alt="" />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-collapse class="mian-left-l">
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/01.png" alt class="enen" />
                            <img src="../../assets/images/D/visa-icon.png" alt class="item-i" />
                            <span class="item-txt-t">Choice of Visa Types</span>
                          </div>
                          <div class="tem-img" v-html="visa1">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="visa1[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="visa1[1]" alt="" /> -->
                            <div class="loginHall-box">
                              <h1 class="copy-title">
                                Classification of Visas
                              </h1>
                              <div class="tab">
                                <el-row>
                                  <el-col :span="6">
                                    <div class="tab-header-td">Types of Visas</div>
                                  </el-col>
                                  <el-col :span="18">
                                    <div class="tab-header-td">
                                      Scope of Applicants
                                    </div>
                                  </el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">C</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Crew members on international aviation and sea transportation, crew members of international aircrafts and vessels, and their accompanying family members, and drivers for land transportation
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">D</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreigners intending to live in China permanently
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">F</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreigners invited to China for exchange, official visits, research study, etc.
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">G</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreigners transiting through China
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">J1</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreign journalists posted to news organizations in China for a long term (more than 180 days)
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">J2</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreign journalists who are on temporary interview assignments in China for a short term (not more than 180 days)
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">L</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Tourists visiting China
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">M</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreigners visiting China for business or trade purposes
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">Q1</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Family members (spouse, parents, children, children’s spouses, siblings, paternal grandparents, maternal grandparents, paternal grandchildren, maternal grandchildren, and in-laws) of Chinese citizens or permanent residents of China who are applying for residency for the purpose of family reunion, or those applying for the purpose of child fostering
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">Q2</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Family members of Chinese citizens or permanent residents of China intending to stay in China short-term (not more than 180 days) to visit family
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">R</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Highly-qualified talent, and talent urgently needed in China
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">S1</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Spouses, parents, children below the age of 18, and in-laws of foreigners residing in China for work or study, who intend to stay for a long term (more than 180 days) to visit family, or for any other personal reasons that require them to be in China
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">S2</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Family members (spouses, parents, children, children’s spouses, siblings, paternal grandparents, maternal grandparents, paternal grandchildren, maternal grandchildren, and in-laws) of foreigners residing in China for work or study, who intend to stay short-term (not more than 180 days) to visit family, or for any other personal reasons that require them to be in China
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">X1</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreign students whose period of study is more than 180 days
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">X2</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreign students whose period of study is not more than 180 days
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">Z</div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          Foreigners employed in China
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                            </div>
                            <div class="set-guid">
                              <h1 class="copy-title">
                                Process flow for visa application<span>（More than 90 days'application for work in China）</span>
                              </h1>
                              <div class="circuit-content">
                                <img width="100%" src="../../assets/images/D/img_02_en.png" alt="">
                              </div>
                              <div class="materialList-box">
                                <h1 class="copy-title">
                                  Notice for Application for Visa of the People's Republic of China
                                </h1>
                                <div class="subTitle">The basic application documents required are as follows:</div>
                                <div class="content-box">
                                  <p>(1) Passport Your passport must be valid for a further six months, must have a blank page for the visa, and you need to submit a photocopy of the passport photo page;</p>
                                  <p>(2) Visa application form and photo: To submit one Application Form for Visa of the People’s Republic of China, and a recent passport photo in color (light color background) showing the person’s face without wearing a hat;
                                  </p>
                                  <p>(3) Legal proof of stay or residency (applicable to applicants outside their home country): If you are applying for a visa outside your home country, you must provide the original and photocopies of valid proof of your stay, residency, work or study in your host country, or your valid visa;</p>
                                  <p>(4) Original Chinese passport or visa (applicable to those who were Chinese citizens but later adopted another nationality): If you are applying for a Chinese visa for the first time, you must provide the original Chinese passport and a photocopy of the passport photo page. If you have been granted a Chinese visa before, and are now applying for a visa with a renewed foreign passport, you must provide a photocopy of the original foreign passport photo page and the Chinese visa previously received (if the name on the new passport is inconsistent with the original passport, you will have to furnish the proof of name change issued by the relevant authority);
                                  </p>
                                  <p>(5) Submit any other documents required according to the visa category.</p>
                                </div>
                                <h1 class="copy-title">
                                  Address and contact details of the application center for foreigner’s work permit
                                </h1>
                                <div class="subTitle">Administrative Services Hall, Civic Center</div>
                                <div class="content-box">
                                  <p>Address: Administrative Services East Hall, Zone B, Civic Center, Fuzhong 3rd Road, Futian District, Shenzhen</p>
                                  <p>Application counters: No. 29-31</p>
                                  <p>Telephone enquiry: 0755-88121678</p>
                                </div>
                              </div>
                              <div>
                                <h1 class="copy-title">
                                  Q & A
                                </h1>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">Is there a medical report required to apply for a visa?</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div
                                    class="item-txt-answer"
                                  >No need.
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">Can the visa check the progress of the permit?</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div
                                    class="item-txt-answer"
                                  >Usually, the embassy or consulate can apply for a visa within four to five working days, and the date of the collection will be indicated on the evidence collection form. Please be patient. If you have special circumstances, you can contact the relevant embassy or consulate by phone.
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">After getting the visa, I found that the number of visas or the stay period is not enough. Can I change it?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div
                                      class="item-txt-answer"
                                    >No. If the applicant changes the planned itinerary so that the number of visas or the duration of the stay cannot meet the actual needs, the applicant can apply for the visa again, but the application materials must be submitted again and paid.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">Can the visa be processed for extension?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div
                                      class="item-txt-answer"
                                    >After entering the country, if you are unable to leave the country according to the original plan, you should go to the local police entry and exit administration department to apply for visa extension before the expiration of the stay. For details, please ask the public security organ.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">Can foreign citizens apply for a visa at a Chinese port?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div
                                      class="item-txt-answer"
                                    >In principle, Chinese visa applicants should apply for visas in advance to Chinese embassies, consulates, offices, Hong Kong, Macao or other foreign agencies authorized by the Ministry of Foreign Affairs. Applicants should complete the visa in advance according to their own itinerary.
                                    <br />
                                    In addition to 24-hour transit, 7-hour transit in 7 cities including Beijing, Shanghai and Shanghai, 6-day convenience tour in Guangdong, and visa-free travel on Hainan on the 15th, US and French citizens must apply for visas abroad beforehand. Apply for a visa at the port.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">How do foreign citizens go to Hong Kong and Macao SAR?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div
                                      class="item-txt-answer"
                                    >The entry policy for Hong Kong and Macao SAR is set by itself, please refer to its government website for specific matters.
                                    <br />
                                    Hong Kong Special Administrative Region Immigration Office website：www.immd.gov.hk；Macao SAR Public Security Police website：0085228246111。
                                    <br />
                                    Macao SAR Public Security Police website：http://www.fsm.gov.mo/；Macao SAR Public Security Police website：0085328725488。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">How should foreign citizens apply for visas at the same time in mainland China and Hong Kong and Macao SAR?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div
                                      class="item-txt-answer"
                                    >Foreign nationals who travel to the Mainland of China, the Hong Kong Special Administrative Region, and the Macao Special Administrative Region must apply for a visa for the Mainland and a visa to Hong Kong and Australia.
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">What do you need to pay attention to when coming to China?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div
                                      class="item-txt-answer"
                                    >（1）Check if the visa is valid and have sufficient entry times.
                                    <br />
                                    （2）Foreigners carrying animals and plants, currency and other countries in China are subject to relevant laws and regulations.
                                    <br />
                                    （3）It is not allowed to engage in activities in China that are not related to the cause of the application when applying for a visa.
                                    <br />
                                    （4）Foreign citizens must not be stranded in China.
                                    <br />
                                    （5）Without permission, you must not enter an area that restricts the entry of foreigners.
                                    <br />
                                    （6）The following circumstances will result in the denial of boarding, being denied entry or being fined, etc. Please take care to avoid:
                                    <br />
                                    · Those who come to China without a visa in advance (except for those who are exempt from visas);
                                    <br />
                                    · Those who come to China with an expired visa;
                                    <br />
                                    · Those who stayed in China during the stay period;
                                    <br />
                                    · During the stay in China, the passport expires (regardless of whether the visa stay period is valid).
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">If a foreigner obtains a Visa for a Foreigner's Work Permit and goes to a foreign embassy or consulate to apply for a visa, is it still necessary to apply for an invitation letter or an invitation confirmation letter?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div
                                        class="item-txt-answer"
                                      >No need
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">Should you pay attention to the employment contract or certificate of employment (including the letter of dispatch from a multinational company)?</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div
                                        class="item-txt-answer"
                                      >The employment contract or employment certificate (including the multinational company dispatch letter) includes the working hours, work place, work content, salary, position, and signature stamp page. When a general employer applies for a work permit business, it is required to provide an employment contract. For the use of the employment certificate, please refer to the remarks of the contract on page 10 of [External Issue [2017] No. 36).
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="code-box">
                                <div class="code-txt">
                                  <p>How Do Foreigners</p>
                                  <p>Work Legally in China?</p>
                                </div>
                                <div class="code">
                                  <img width="100%" src="../../assets/images/D/code.png" alt="">
                                </div>
                              </div>
                              </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/02.png" alt class="enen" />
                            <img src="../../assets/images/D/id-card.png" alt class="item-i" />
                            <span class="item-txt-t">
                              <p>Processing Foreigner's Permanent</p>
                              <p>Residence Identity Card for the First Time</p>
                            </span>
                            <span class="item-txt-t" style="top: 80px;"></span>
                          </div>
                          <div class="tem-img" v-html="visa2">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="visa2[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="visa2[1]" alt="" /> -->
                            <div class="cnts-item i-night2">
                              <div>
                                <h1>Application requirements</h1>
                                <p>Foreigners applying for permanent residency in China must comply with the laws of China, be in good health, hold no criminal record, and meet one of the following criteria:</p>
                                <p>1、 Having direct investments in China with good performance for a consecutive three years, and having a good tax payment record;</p>
                                <p>2、Holding the position of a deputy general manager, deputy factory manager or any other similar position, or an associate professor, a research associate or any other deputy senior position in China, and receiving equivalent benefits, on a continuous tenure of more than four years, with a cumulative period of residency of not less than three years and having a good tax payment record;</p>
                                <p>3、 Foreigners who have made significant and outstanding contributions to China and are urgently needed by the country;</p>
                                <p>4、 Spouses and unmarried children not older than 18 years old of individuals referred to in item 1, 2 and 3 above;</p>
                                <p>5、Spouses of Chinese citizens or foreigners who have permanent residency in China, having been in the marriage for at least five years, having resided in China continuously for five years, during which no less than nine months each year is spent in China, and having a stable means of living and residence;</p>
                                <p>6、Unmarried children not older than 18 years who are looking to depend on their parents in China;</p>
                                <p>7、Individuals of 60 years of age or above, who do not have direct relations outside China and are looking to depend on direct relations in China, having resided in China continuously for five years, during which time no less than nine months each year is spent in China, and having a stable means of living and residence.</p>
                              </div>
                              <div>
                                <h1>Foreigner permanent residence ID card initial application process </h1>
                                <img src="../../assets/images/D/night-flow-en.png" alt="">
                              </div>
                              <div>
                                <h1>Fee rates</h1>
                                <p>1、Processing fee: RMB 1500; (non-refundable even if application is rejected)</p>
                                <p>2、Fee for Foreigner’s Permanent Resident Card: RMB 300.</p>
                              </div>
                              <div>
                                <h1>Tips</h1>
                                <p>1、Documents of proof issued by foreign authorities must be notarized by the Chinese embassy or consulate in that country.Documents in a foreign language must be translated into Chinese, and the translation document and the photocopy of the business license of the translation company must be stamped with the seal of the translation company.</p>
                                <p>2、 If the applicant wants their Chinese name typed on the Foreigner’s Permanent Resident Card, they must specify “please include Chinese name XXX on the card” in the “Other Details” section on the application form, otherwise it may be filled in as “N/A”.</p>
                                <p>3、If any matter requires investigation and verification by the public security authorities, the period of investigation shall not include working days. Currently, the time required to process a permanent residency application is around one year.</p>
                              </div>
                              <div>
                                <h1>Telephone enquiry </h1>
                                <p>1、Bureau of Exit and Entry Administration of the Shenzhen Municipal Public Security Bureau</p>
                                <i>Address: No. 4016, Jiefang Road, Luohu District, Shenzhen</i>
                                <i>Tel No.: 84465490</i>
                              </div>
                              <div>
                                <p>2、Management Committee of Shenzhen’s Qianhai Shekou Free Trade Zone</p>
                                <i>Address: Integrated Office for the Qianhai Shenzhen-Hong Kong Modern Service Industry Cooperation Zone, South of Intersection of Dongbin Road and Yueliangwan Boulevard, Nanshan District, Shenzhen</i>
                                <i>Telephone enquiry: 88988988</i>
                              </div>
                              <div>
                                <p>3、Shenzhen Administration of Foreign Experts Affairs</p>
                                <i>Address: Shenzhen Talent Park, No. 8005, Shennan Boulevard, Futian District, Shenzhen</i>
                                <i>Telephone enquiry：88123456</i>
                              </div>
                              <div>
                                <p>4、Overseas Chinese Affairs Office of Shenzhen</p>
                                <i>Address: 6th - 7th Floor, Second Municipal Government Office, No. 1023, Shangbu Middle Road, Futian District, Shenzhen</i>
                                <i>Telephone enquiry：88136167</i>
                              </div>
                              <div>
                                <p>5、Shenzhen Port Hospital</p>
                                <i>Address: Huanggang Port Residential Area, Binhe Boulevard, Fujian District, Shenzhen</i>
                                <i>Telephone enquiry：83774013</i>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/03.png" alt class="enen" />
                            <img src="../../assets/images/D/policy.png" alt="" class="item-i" />
                            <span class="item-txt-t">Policy support</span>
                          </div>
                          <div class="tem-img" v-html="visa3">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="visa3[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="visa3[1]" alt="" /> -->
                            <div class="cnts-item i-night3">
                              <div>
                                <h1>Furthermore, foreigners meeting the following criteria may also apply for permanent residency :</h1>
                                <ul>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">01</div>
                                        <div class="item-txt-c">Foreign members of innovative enterprise teams in Shenzhen and foreign technology talent selected and employed by enterprises;</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code1.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">02</div>
                                        <div class="item-txt-c"> Foreign ethnic Chinese working in Shenzhen</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code2.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">03</div>
                                        <div class="item-txt-c">Foreigners whose annual salary income and personal income tax meet the relevant standards</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code3.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">04</div>
                                        <div class="item-txt-c">Foreigners who are appointed personnel, their spouses, and their children who are minors (appointed personnel and family category);</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code4.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">05</div>
                                        <div class="item-txt-c">Foreigners who are special personnel, their spouses, and their children who are minors (special personnel category)</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code5.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">06</div>
                                        <div class="item-txt-c"> Foreigners looking to depend on family members with household registration in Shenzhen (dependents category);</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code6.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">07</div>
                                        <div class="item-txt-c">Top foreign talent who have worked for a full three years</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code7.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">08</div>
                                        <div class="item-txt-c">Foreigners who are minors looking to reunite with parents in Shenzhen (family reunion category)</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code8.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">09</div>
                                        <div class="item-txt-c">Foreigners having investments in China, their spouses, and their children who are minors (investors and family category)</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code9.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">10</div>
                                        <div class="item-txt-c">Foreigners who married an individual with household registration in Shenzhen</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code10.png" alt="">
                                            <p>For more detaills
                                            <br />
                                            please scan the QR code</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane class="cyyy" label="Setting up a company" name="5">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/SET.png" alt="" />
                    <img class="hidden-md-and-up" width="50%" src="../../assets/images/en/A/SET-m.png" alt="" />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-collapse class="mian-left-l">
                        <div class="title-box">
                          <div>
                            <div class="text">
                              The company is established to be operational and needs to go through industrial and commercial registration (application for company business
                              license)、
                            </div>
                            <div class="text">Engraving seals, corporate banking account opening and other links.</div>
                          </div>
                        </div>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/01.png" alt class="enen" />
                            <img src="../../assets/images/D/pencil.png" alt class="item-i" />
                            <span class="item-txt-t">Business registrations</span>
                          </div>
                          <!-- <div class="tem-img"> -->
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="setting1[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="setting1[1]" alt="" /> -->
                            <div v-html="setting1">
                              <div class="set-guid">
                                <h1 class="copy-title">
                                  Process flow for company registration (services in Chinese-language only)
                                </h1>
                                <div class="circuit-content">
                                  <img width="100%" src="../../assets/images/D/circuit-en.png" alt="">
                                  <p>Remarks: Processing time indicated is for reference only.</p>
                                </div>
                                <h1 class="copy-title">
                                  Foreign-invested enterprises set up a registration and registration process.(services in Chinese-language only)
                                </h1>
                                <div class="circuit-content">
                                  <img width="100%" src="../../assets/images/D/D5-1-1-img01-en.jpg" alt="">
                                  <p>Remarks: Processing time indicated is for reference only.</p>
                                </div>
                                <div class="materialList-box">
                                  <h1 class="copy-title">
                                    What documents are needed for a company registration? <span>Documents that are required when registering on-site at the Registration Hall are as follow (only Chinese-language documents accepted):</span>
                                  </h1>
                                  <div class="tab">
                                    <el-row>
                                      <el-col :span="6">
                                        <div class="tab-header-td">Shareholders’ structure</div>
                                      </el-col>
                                      <el-col :span="18">
                                        <div class="tab-header-td">
                                          List of documents (the following information is for reference only)
                                        </div>
                                      </el-col>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="6">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              <p>Natural person</p>
                                              <p>(Wholly foreign-owned)</p>
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="18">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              <p>1.Shareholders provide personal notarized certificates or sign before the application submission personnel at the local registration hall;</p>
                                              <p>2.Photocopies of identification documents of shareholders, directors, supervisors, managers and finance executives (Hong Kong and Macau residents: Mainland travel permits and personal identification cards; Foreigners: Passports; Taiwanese: Mainland travel permits for Taiwan residents) </p>
                                              <p>3.Original passports of shareholders;</p>
                                              <p>4.Photocopies of personal identification cards of designated recipients of legal documents (who must be a Chinese national)</p>
                                              <p>5.Application Form for Company Registration (Joint Issuance of Business License and Social Credit Code) (downloadable on website)</p>
                                              <p>6.Corporate charter (template downloadable on website)</p>
                                              <p>7.Letter of appointment</p>
                                              <p>8.Office leasing agreement and proof </p>
                                              <p>(to be provided according to relevant local requirements)</p>
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="6">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              <p>Corporate legal person</p>
                                              <p>(Wholly foreign-owned)</p>
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="18">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              <p>1.Proof of legal entity status of foreign investors;</p>
                                              <p>2.Photocopies of identification documents of directors, supervisors, managers and finance executives (Hong Kong and Macau residents: Mainland travel permits and personal identification cards; Foreigners: Passports; Taiwanese: Mainland travel permits for Taiwan residents)</p>
                                              <p>3.Photocopies of personal identification cards of designated recipients of legal documents (who must be a Chinese national)</p>
                                              <p>4.Application Form for Company Registration (Joint Issuance of Business License and Social Credit Code) (downloadable on website)</p>
                                              <p>5.Corporate charter (template downloadable on website)</p>
                                              <p>6.Letter of appointment</p>
                                              <p>7.Office leasing agreement and proof (to be provided according to relevant local requirements)</p>
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="6">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              <p>Chinese-foreign jointventure enterprises</p>
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="18">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              <p>1.The Chinese shareholder must be a legal entity and not a natural person. The photocopy of the business license stamped with the company’s seal is to be provided;</p>
                                              <p>2.The foreign shareholder can be a natural person or legal entity. The natural person shareholder shall provide his or her personal notarized certificate or sign before the application submission personnel at the local registration hall. The legal entity shareholder shall provide proof of their legal entity status as a foreign investor;</p>
                                              <p>3.Photocopies of identification documents of directors, supervisors, managers and finance executives (Hong Kong and Macau residents: Mainland travel permits and personal identification cards; Foreigners: Passports; Taiwanese: Mainland travel permits for Taiwan residents)</p>
                                              <p>5.Application Form for Company Registration (Joint Issuance of Business License and Social Credit Code) (downloadable on website)</p>
                                              <p>6.Corporate charter (template downloadable on website)</p>
                                              <p>7.Letter of appointment</p>
                                              <p>8.Office leasing agreement and proof (to be provided according to relevant local requirements)</p>
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                  </div>
                                </div>
                                <h1 class="copy-title">
                                  List of application documents for the integrated management of foreign-invested enterprises on the unified platform of the Ministry of Commerce’s operations system:
                                </h1>
                                <div class="list-content">
                                  <p>
                                    1.Proof of commercial entity name of foreign-invested enterprise (download at Step ② of the declaration process);
                                  </p>
                                  <p>2.Letter of Commitment for Record-Filing for Registration of Foreign-Invested Enterprise (template downloadable on website);</p>
                                  <p>3.Proof of legal entity status or natural person identity of investors;</p>
                                  <p>4.Foreign-invested enterprises set up a filing application power of attorney (download sample on the website);</p>
                                  <p>5.Proof of natural person identity of legal representative;</p>
                                  <p>6.Shareholding framework of actual controlling persons of foreign-invested enterprise (template downloadable on website).</p>
                                </div>
                                <div>
                                  <h1 class="copy-title">
                                    Question answer
                                  </h1>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">How is the registered capital appropriate?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div
                                      class="item-txt-answer"
                                    >The registered capital can be formulated according to the needs of the company's own development. Except for special industries and free trade zones, there is no minimum registered capital requirement. In the company's articles of association, the time limit for paying the registered capital and the number of times of payment may be stipulated by itself, and may be paid in multiple times. The amount paid each time is not subject to specific requirements.
                                    </div>
                                  </div>
                                </div>
                            </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">What are the special requirements for reducing registered capital?</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div
                                    class="item-txt-answer"
                                  >The "Company Law" stipulates that the reduction of registered capital needs to be announced in the newspaper, and the capital reduction registration can be processed after 45 days.
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">The proportion of foreign investment is less than 25%. Can you be a foreign-invested company? </div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">
                                    can. The registered capital of a Sino-foreign joint venture is generally not less than 25%. If the foreign investor's capital contribution ratio is less than 25%, unless otherwise stipulated by laws and administrative regulations, it shall be examined and registered according to the current examination and approval registration procedures for the establishment of foreign-invested enterprises. If the registration is successful, it shall be issued after the “enterprise type”. Add the business license of a foreign-invested enterprise with the words “the ratio of foreign investment is less than 25%”.
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">Who can be the legal representative?</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div
                                    class="item-txt-answer"
                                  >The legal representative can only be held by the chairman, executive director or manager, and the person who is appointed by the company's articles of association, but not by those other than the three.
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">In the catering business in China, in addition to applying for a business license, do you need to apply for other documents?</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div
                                    class="item-txt-answer"
                                  >
                                    If necessary, you need to apply for a food business license.
                                  </div>
                                </div>
                              </div>
                              <div class="loginHall-box">
                                <div class="title-note">Addresses and contact information of company registration halls in Shenzhen:</div>
                                <div class="tab">
                                  <el-row>
                                    <el-col :span="6">
                                      <div class="tab-header-td">Name of Bureau of Industry and Commerce</div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-header-td">
                                        Address:
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-header-td">
                                        Contact Details
                                      </div>
                                    </el-col>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>Electronic One-Stop Service Center Hall </p>
                                            <p>(Qianhai Bureau of Industry and Commerce)</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>One-Stop Service Center Hall A105, Building A, No. 1, Qianwan 1st Road, Qianhai Shenzhen-Hong Kong Cooperation Zone, Shenzhen</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Registration hall of Futian Sub-Bureau
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>1st Floor, Luohu Management Center Building, No. 1008, Wenjin Middle Road, Luohu District, Shenzhen</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Administrative Services Hall of Luohu District
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>Administrative Services Hall of Nanshan District, Shenzhen Bay Sports Center, No. 3001, Binhai Boulevard Side Road, Nanshan District </p>
                                            <p>(Single building south-east of Shenzhen Bay Sports Center)</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Registration Hall of Bao’an Sub-Bureau
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>1st Floor, Integrated Training Building of Bao’an Sports Center, Intersection of Bao’an Boulevard and Luotian Road, Bao’an District, Shenzhen </p>
                                            <p>(Opposite the Bao’an Immigration Permit Application Hall)</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Registration Hall of Longgang Sub-Bureau
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>No. 8033-1, Longxiang Boulevard, Longcheng Subdistrict, Longgang District </p>
                                            <p>(south-west of main gate of District Government Office)</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Registration hall of Yantian Sub-Bureau
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>1st Floor, Yantian Sub-Bureau, No. 1013, Haijing 2nd Road, Shatoujiao, Yantian District</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Registration hall of Guangming Sub-Bureau
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>Administrative Services Hall of Guangming District, Biyan Road, Guangming District, Shenzhen</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Registration hall of Pingshan Sub-Bureau
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>2nd Floor, Qixing Building, No. 12, Jinniu West Road, Pingshan District</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Registration hall of Dapeng Sub-Bureau
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>No. 10, Zhongshan Road, Dapeng Boulevard, Dapeng New District, Shenzhen</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            Registration hall of Longhua Sub-Bureau
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="12">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            <p>1st Floor, Block A, Guohong Building, No. 98, Meilong Boulevard, Longhua District, Shenzhen</p>
                                          </div>
                                        </div>
                                      </el-col>
                                      <el-col :span="6">
                                        <div class="tab-content-td">
                                          <div class="box">
                                            12345
                                          </div>
                                        </div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                </div>
                              <!-- </div> -->
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/02.png" alt class="enen" />
                            <img src="../../assets/images/D/seal.png" alt class="item-i" />
                            <span class="item-txt-t">Chapter moment</span>
                          </div>
                          <div class="tem-img" v-html="setting2">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="setting2[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="setting2[1]" alt="" /> -->
                            <div class="cnts-item i-eit2">
                              <div>
                                <p>After successfully registering with the Market and Quality Supervision Commission of Shenzhen Municipality, the company’s legal representative will receive a verification code sent through the “Government Affairs Messaging Platform”. With the verification code, the company can have its seals made either online or at any physical seal-making shop.</p>
                                <p>(1)Official seal/administrative seal (required for opening business bank accounts)</p>
                                <p>(2)Financial seal (required for opening business bank accounts)</p>
                                <p>(3)Legal representative’s personal seal (with personal name) (required for opening business bank accounts)</p>
                                <p>(4) Invoice seal (to stamp on invoices)</p>
                                <p>(5)Agreement seal (for signing of agreements)</p>
                              </div>
                              <div class="cnts2">
                                <p>Remarks：</p>
                                <p>Under the line engraved chapters engraved chapters, must carry information:</p>
                                <p>Original/copy of the business license;</p>
                                <p>Original legal representative passport;</p>
                                <p>The original ID of the manager.</p>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/03.png" alt class="enen" />
                            <img src="../../assets/images/D/wallet.png" alt="" class="item-i" />
                            <span class="item-txt-t">Company bank account opening</span>
                          </div>
                          <div class="tem-img" v-html="setting3">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="setting3[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="setting3[1]" alt="" /> -->
                              <div class="cnts-item i-eit3">
                                <p style="text-indent: 2em;padding-bottom: .4rem;">Once the business license and company seals are ready, the company can begin its operations. If the company wants to deal with payments in its name with suppliers, a business bank account will be needed. The company cannot provide the bank account details of its shareholders to the suppliers, as this might lead the tax authorities to suspect tax evasion.</p>
                                <div>
                                  <h1>Corporate bank account type</h1>
                                  <p style="padding-bottom: .2rem;">① Basic account (required, and only one allowed). A basic account is the first bank account that an enterprise needs. You must first have a basic account to be able to open other accounts. A basic account allows you to deposit and withdraw funds, transfer or receive payments, pay withholding tax, pay wages, etc.</p>
                                  <p>② Foreign currency capital account (required for foreign-invested enterprises):</p>
                                  <p style="padding-bottom: .2rem;">This is an account for receiving capital paid by Chinese and foreign shareholders in foreign currencies. When making payments from a foreign bank to a foreign currency capital account in China, the purpose of payment must be stated as “investment” or “capital”. This is to make it easier for the auditor to identify the amount as capital in the capital verification report or audit report.At the same time, the bank will have set certain rules regarding the use of the registered capital in accordance to the instructions and requirements of the Administration of Foreign Exchange. Please be sure to adhere to the specific requirements of each bank. For example: A foreign trade company that intends to make payments to its suppliers must present the relevant signed agreements, invoices and other documents at the bank counter to apply for the account before it is able to make any overseas payments.</p>
                                  <p>③General account (not required, multiple accounts allowed)</p>
                                  <p> An enterprise may open a general account locally, which allows for the deposit of cash, but not withdrawal. Only bank transfers are allowed, as well as payment of withholding tax.</p>
                                  <p>After learning about the different types of business bank accounts available, Leanne has the following question: Where should I open the accounts, then?</p>
                                </div>
                                <div>
                                  <h1>How to find information about the bank?</h1>
                                  <p style="text-indent: 2em;">in China, you can call the customer service number of each bank, such as Bank of China: 95566. You can tell the bank which bank you want to open a company bank account in, and the bank customer service staff will also inform you of the bank's phone number and address. Next, you can call the bank phone or at the address provided by the customer service to ask at the bank counter. The information to be understood.</p>
                                </div>
                                <div class="cnts2">
                                  <div class="tab">
                                    <i>Attachment: Important bank customer service phone</i>
                                    <i>By dialing the numbers as follows, you will be directed to the English-language service of the respective bank:</i>
                                    <el-row>
                                      <el-col :span="8">
                                        <div class="tab-header-td">Bank</div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-header-td">
                                          Customer service numbe
                                        </div>
                                      </el-col>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              Bank of China
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95566 press 8
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              China Construction Bank
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95533 press 4
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              Industrial and Commercial Bank of China
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95588 press 0
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              Agricultural Bank of China
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95599 press 9
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              China Merchants Bank
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95555 press 8
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              Shanghai Pudong Development Bank
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95528 press 2
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              China CITIC Bank
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95558 press 4
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              China Minsheng Bank
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95568 press 9
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              China Everbright Bank
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95595 press* press 7
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                    <el-row>
                                      <div class="tab-content-tr">
                                        <el-col :span="8">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              China Guangfa Bank
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :span="16">
                                          <div class="tab-content-td">
                                            <div class="box">
                                              95508 press 7#
                                            </div>
                                          </div>
                                        </el-col>
                                      </div>
                                    </el-row>
                                  </div>
                                </div>
                                <div>
                                  <h1>Where should I open the accounts, then?</h1>
                                  <p>1.You can choose any bank near you. You can take a look at the area where your office is for any suitable bank. It might be good to choose a bank close to your office, as it’ll save time when the company is in operation and you will need to go to the bank every so often for banking errands.</p>
                                  <p>2.Choose a bank you are familiar with. If you have used Bank of China before while overseas, then you can once again open a business account at a Bank of China branch in Shenzhen. It can be easier to learn more about the bank when you are already familiar with its operation and management.</p>
                                </div>
                                <div>
                                  <h1>How should I make an appointment to open a bank account?</h1>
                                  <p>1.You may schedule an appointment on the phone with the bank staff;</p>
                                  <p>2.You may walk in and make an appointment at your selected bank;</p>
                                  <p>3.You may also schedule an appointment through the bank’s website or the bank’s WeChat public account. The public account only provides services in Chinese, and to use it you must have a Mainland China mobile number that can receive mobile phone verification codes from Mainland China.</p>
                                </div>
                                <div>
                                  <h1>Additionally, you also need to bring along some cash to the bank for the relevant fee payments:</h1>
                                  <p>1.Account-opening fee;</p>
                                  <p>2.Account management fee (usually paid monthly);</p>
                                  <p>3.USB Key processing fee;</p>
                                  <p>4.Online banking management fee (paid monthly or quarterly);</p>
                                  <p>5.Bank transfer fee.</p>
                                </div>
                                <div>
                                  <h1>Process flow for opening a basic bank account :<span>(for reference only - please refer to the individual requirements of each bank)</span></h1>
                                  <img src="../../assets/images/D/eit3-flow-en.png" alt="">
                                </div>
                                <div>
                                  <h1>List of documents required for opening a basic account :<span>(for reference only - please refer to the individual requirements of each bank)</span></h1>
                                  <p>1.Original business license of the company;</p>
                                  <p>2.Original office leasing agreement and proof;</p>
                                  <p>3.Original trust agreement for company office in Qianhai (required for enterprises in the Qianhai Free Trade Zone)</p>
                                  <p>4.The legal representative needs to attend to the application in person at the bank account, and have his or her picture taken by the bank employee;</p>
                                  <p>5.Original passport of the legal representative;</p>
                                  <p>6The company’s official seal, financial seal, and legal representative’s personal seal;</p>
                                  <p>7.Original passport of the company’s online banking executive;</p>
                                  <p>8.Original passports of natural person shareholders.</p>
                                  <p style="color: #0fb7d1;padding-top: .2rem;">The bank employee may ask the legal representative the following questions when handling the application in person:</p>
                                  <p>1.What is the registered address of the company? Is it the same as the company’s office address? </p>
                                  <p>2.What is the main business area of the company? </p>
                                  <p>3.How many employees does the company currently have? </p>
                                  <p>4.Who are the company’s shareholders? What are their capital shares? </p>
                                  <p>5.Why do you want to open a bank account?</p>
                                </div>
                                <div>
                                  <h1>Process flow for opening a foreign currency capital account :<span>(for reference only - please refer to the individual requirements of each bank)</span></h1>
                                  <img src="../../assets/images/D/eit3-flow2-en.png" alt="">
                                </div>
                                <div>
                                  <h1>List of documents required for applying for FDI :<span>(for reference only - please refer to the individual requirements of each bank)</span></h1>
                                  <p>1.Original business license;</p>
                                  <p>2.Original office leasing agreement and proof;</p>
                                  <p>3.Original and photocopy of the passport of the legal representative (whether he or she needs to be at the bank in person depends on the bank’s requirements);</p>
                                  <p>4.Original and photocopy of the account-opening permit;</p>
                                  <p>5.Company’s official seal, financial seal, and legal representative’s personal seal;</p>
                                  <p>6.Original receipt of acknowledgement of record-filing for registration of foreign-invested enterprises;</p>
                                  <p>7.Original record-filing declaration form for registration of foreign-invested enterprises;</p>
                                  <p>8.Original corporate charter;</p>
                                  <p>9.Original personal identification card of application representative;</p>
                                  <p>10.Photocopy of business license of domestic enterprise shareholders stamped with the official seal (required for Chinese-foreign joint venture enterprises);</p>
                                  <p>11.Photocopies of passports of foreign natural person shareholders;</p>
                                </div>
                                <div>
                                  <h1>List of documents required for opening a foreign currency capital account :<span>(for reference only - please refer to the individual requirements of each bank)</span></h1>
                                  <p>1.Original business license;</p>
                                  <p>2.Original office leasing agreement and proof;</p>
                                  <p>3.Original trust agreement for company office in Qianhai (required for enterprises in the Qianhai Free Trade Zone)</p>
                                  <p>4.The legal representative needs to attend the application in person at the bank, and have his or her picture taken by the bank employee;</p>
                                  <p>5.Original and photocopy of the passport of the legal representative;</p>
                                  <p>6.Company’s official seal, financial seal, and legal representative’s personal seal;</p>
                                  <p>7.Original and photocopy of the passport of the payment contact person;</p>
                                  <p>8.Original receipt of acknowledgement of record-filing for registration of foreign-invested enterprises;</p>
                                  <p>9.Original record-filing declaration form for registration of foreign-invested enterprises;</p>
                                  <p>10.Original corporate charter;</p>
                                  <p>11.Original personal identification card of application representative;</p>
                                  <p>12.Original business registration certificate.</p>
                                </div>
                                <div>
                                  <h1>Foreign exchange settlement in a foreign currency capital account:</h1>
                                  <p>Incoming transactions for a capital account include: Foreign currency capital or subscribed capital remitted by foreign investors, and its interest.</p>
                                  <p>Outgoing transactions for a capital account include: Financial exchange settlement for business operations, debited payments due to reduction or withdrawal of capital by foreign investors, payment for goods and services to suppliers, wages, withdrawal of capital reserves (the total amount withdrawn monthly by a company from its capital reserves must not exceed the equivalent of $100,000 USD.)</p>
                                  <p>How are payments for goods or services made from a capital account?</p>
                                  <p>Any direct payment for goods or services to suppliers from a capital account can only be made by the bank after the account holder has submitted relevant documents to the bank counter.</p>
                                  <p>1.Service trading. To submit documents such as the service agreement and invoices;</p>
                                  <p>Goods trading. To submit documents such as agreements, invoices, and customs bills of payment.</p>
                                </div>
                                <div>
                                  <h1>Question answer</h1>
                                  <ul>
                                    <li>
                                      <div class="item-txt-a">
                                        <div>
                                          <div class="item-txt-b">Q</div>
                                        </div>
                                        <div class="item-txt-c">What are the special points for attention before preparing to open a basic bank account for a foreign-funded enterprise?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div>
                                          <div class="item-txt-question">A</div>
                                        </div>
                                        <div
                                          class="item-txt-answer"
                                        >
                                        <div class="cnts">
                                          Both the legal representative and the online banking administrator need to apply for a mainland mobile phone number to keep in touch with the bank, receive verification codes, and so on.
                                        </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div>
                                          <div class="item-txt-b">Q</div>
                                        </div>
                                        <div class="item-txt-c">After the completion of the engraving, what time do you need to complete the opening of the basic bank account of the foreign-funded enterprise (the first account of the company)?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div>
                                          <div class="item-txt-question">A</div>
                                        </div>
                                        <div
                                          class="item-txt-answer"
                                        >
                                        <div class="cnts">
                                          There is no specific requirement for the opening time of the corporate bank account, and the enterprise can arrange the time according to its actual operation. If there is no business in the near future, you can not open a basic bank account. It takes about 1-2 months to process basic households and foreign exchange capital households, which is subject to the actual situation of each bank.
                                        </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div>
                                          <div class="item-txt-b">Q</div>
                                        </div>
                                        <div class="item-txt-c">What are the currencies of the foreign exchange capital account? What is the difference between accounts in different currencies?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div>
                                          <div class="item-txt-question">A</div>
                                        </div>
                                        <div
                                          class="item-txt-answer"
                                        >
                                        <div class="cnts">
                                          The currency of the foreign exchange capital account can be overseas RMB or other foreign currency (mainly US dollars). No matter which currency the foreign exchange capital account is used, there is not much difference. In the application process time, the overseas RMB capital account will be longer, and the bank will increase the RMB compliance review. Obtain source and legality for review.
                                        </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div>
                                          <div class="item-txt-b">Q</div>
                                        </div>
                                        <div class="item-txt-c">When applying for bank account opening, the legal representative must go to the bank counter, and the legal representative must go to the bank to receive the signature card and the account opening permit.Is it handled at the counter?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div>
                                          <div class="item-txt-question">A</div>
                                        </div>
                                        <div
                                          class="item-txt-answer"
                                        >
                                        <div class="cnts">
                                          n addition to the legal representative on the day of the application, the collection link can authorize the company's employees to go to the bank counter.
                                        </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div>
                                          <div class="item-txt-b">Q</div>
                                        </div>
                                        <div class="item-txt-c">How many corporate online banking U shields? how to use?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div>
                                          <div class="item-txt-question">A</div>
                                        </div>
                                        <div
                                          class="item-txt-answer"
                                        >
                                        <div class="cnts">
                                          2-3. The functions of the two online banking U shields are (manufacturing, review). Under normal circumstances, the online financial U shield with the legal representative holds the review function, and the enterprise financial personnel hold the online banking U shield with the function of the single order. When paying with the online banking U Shield, it cannot be operated by one person. At least two people need to operate (manufacturing and review) to ensure the safety of the company's funds.
                                        </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div>
                                          <div class="item-txt-b">Q</div>
                                        </div>
                                        <div class="item-txt-c">After the opening of the corporate bank account, is it not necessary to manage?</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div>
                                          <div class="item-txt-question">A</div>
                                        </div>
                                        <div
                                          class="item-txt-answer"
                                        >
                                        <div class="cnts">
                                          Banks and enterprises need to reconcile monthly/quarterly, and enterprises can log in through online banking U shield. If it is not possible to reconcile in time, it will most likely be listed by the bank as a long-term deposit, and the bank account will be frozen. Once it is listed as a long-term deposit, it needs to be thawed and then reopened. The process is cumbersome and time consuming.
                                        </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
              <div class="downn"><i class="el-icon-caret-bottom"></i></div>
            </div>
            <gotop></gotop>
          </el-col>
        </el-row>
      </div>
    </el-main>
    <el-footer>
      <vfootere class="hidden-sm-and-down"></vfootere>
      <ivfootere class="hidden-md-and-up"></ivfootere>
    </el-footer>
  </el-container>
</template>

<script>
import { szLifed1, szLifed11, szLifed21, szLifed22, szLifed3, szLifed4, szLifed5 } from '../../api-en'
import Gotop from '../../components/gotop'
export default {
  data () {
    return {
      activeName: 'first',
      tabPosition: 'right',
      acName: '1',
      btnFlag: false,
      subShow: false,
      subsidies: {},
      subsidies1: '',
      subsidies2: '',
      subsidies21: {},
      subsidies22: {},
      subsidies23: {},
      subsidies24: {},
      subsidies25: {},
      essential: {},
      essential12: {},
      essential13: {},
      essential14: {},
      essential2: {},
      essential21: {},
      essential22: {},
      essential23: {},
      taxLaw1: {},
      taxLaw2: {},
      taxLaw3: {},
      taxLaw4: {},
      visa1: {},
      visa2: {},
      visa3: {},
      setting1: {},
      setting2: {},
      setting3: {},
      navTitle: [
        {
          txt: 'Individuals'
        },
        {
          txt: 'Enterprises'
        }
      ],
      menuShow: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
    szLifed1().then(res => {
      this.subsidies2 = res.data[0].ONE
      // console.log(res.data)
    })
    szLifed11().then(res => {
      this.subsidies1 = res.data[0].CONTENT
    })
    szLifed21().then(res => {
      this.essential = res.data[0]
      this.essential12 = this.cutSrc(res.data[0].TWO)
      this.essential13 = res.data[0].THREE
      this.essential14 = res.data[0].FOUR
    })
    szLifed22().then(res => {
      this.essential2 = res.data[0]
      this.essential21 = res.data[0].TWO
      this.essential22 = res.data[0].THREE
      this.essential23 = res.data[0].FOUR
    })
    szLifed3().then(res => {
      this.taxLaw1 = res.data[0].ONE
      this.taxLaw2 = res.data[0].TWO
      this.taxLaw3 = res.data[0].THREE
      this.taxLaw4 = res.data[0].FOUR
    })
    szLifed4().then(res => {
      this.visa1 = res.data[0].ONE
      this.visa2 = res.data[0].TWO
      this.visa3 = res.data[0].THREE
      // console.log(this.visa)
    })
    szLifed5().then(res => {
      this.setting1 = res.data[0].ONE
      this.setting2 = res.data[0].TWO
      this.setting3 = res.data[0].THREE
      // console.log(this.setting)
    })
    this.$nextTick(() => {
      $('#pane-1 #tab-first').append(
        `
				<p class="tit">Subsidy policies for individuals</p>
				<p class="tit-name">个人政策</p>
				`
      )
      $('#pane-1 #tab-second').append(
        `
				<p class="tit">Policies for enterprises</p>
				<p class="tit-name">企业政策</p>
				`
      )
      $('#pane-2 #tab-first').append(
        `
				<p class="tit">Choosing the right company type</p>
				<p class="tit-name">公司性质选择</p>
				`
      )
      $('#pane-2 #tab-second').append(
        `
				<p class="tit">Intellectual property</p>
				<p class="tit-name">知识产权</p>
				`
      )
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  components: {
    Gotop
  },
  methods: {
    cutSrc (obj) {
      var a = obj.split('</p>')
      // eslint-disable-next-line no-array-constructor
      var b = new Array()
      for (var i = 0; i < a.length; i++) {
        if (a[i].indexOf('src') !== -1) {
          b.push(a[i].match(/src="(\S*)"/)[1])
        }
      }
      return b
    },
    menuOpen () {
      this.menuShow = !this.menuShow
    },
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    subSelect (e) {
      if (e === 0) {
        this.activeName = 'first'
      } else if (e === 1) {
        this.activeName = 'second'
      }
    },
    handleOpen (e) {
      // console.log(e)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop () {
      let that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    mainSelect (e) {
      this.acName = e
      this.menuShow = false
      this.backTop()
      // console.log(document.getElementsByClassName('el-menu--inline'))
      // document.getElementsByClassName('el-menu--inline')[0].setAttribute('style', 'display:none')
      if (e == 1) {
        this.navTitle = [
          {
            txt: 'Individuals'
          },
          {
            txt: 'Enterprises'
          }
        ]
      } else if (e == 2) {
        this.navTitle = [
          {
            txt: 'Company type'
          },
          {
            txt: 'Intellectual property'
          }
        ]
      }
    },
    getElementTopLeft (obj) {
      var top = 0
      var left = 0
      while (obj) {
        top += obj.offsetTop
        left += obj.offsetLeft
        obj = obj.offsetParent
      }
      return {
        top: top,
        left: left
      }
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop () {
      let that = this
      let num = 1
      if (this.acName <= 2) {
        num = this.acName
      } else {
        num = 3
      }
      let menuObj = document.getElementsByClassName('el-tabs__header')[num]
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 100) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
      if (num <= 2) {
        if (num === 1) {
          // eslint-disable-next-line standard/object-curly-even-spacing
          this.navTitle = []
          this.navTitle = this.navTitle.concat(
            {
              txt: '公司性质'
            },
            {
              txt: '知识产权'
            }
          )
        }
        if (this.getElementTopLeft(menuObj).top + menuObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight > this.getElementTopLeft(menuObj).top) {
          this.subShow = false
          // console.log(num)
        } else {
          this.subShow = true
        }
      } else {
        this.subShow = false
      }
    }
  }
}
</script>

<style lang="less">
.cityDe{
  .el-collapse-item__content img {
    width: 100%;
  }

  .tem-img {
    padding: 0.7rem 0.4rem 0.2rem 0.4rem;
  }

  .nav-title {
    width: 80%;
    position: relative;
    text-align: left;
    margin-bottom: 0.8rem;
  }

  .el-main {
    margin-top: 0;
    overflow: initial;
  }

  .mida {
    position: relative;
  }

  .top-img .ec-top-foot {
    position: absolute;
    bottom: 5px;
    left: 16%;
    background: rgba(0, 182, 205, 1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }

  .top-img .ec-top-foot .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
  }

  .mida .ec-top-foot {
    position: absolute;
    top: -1.23rem;
    left: 18.5%;
    background: rgba(0, 182, 205, 1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }

  .mida .ec-top-foot .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
  }

  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }

  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }

  .top-img {
    position: relative;
  }

  .top-img-top {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #ffffff;
    text-align: left;
  }

  .top-img-top p {
    font-size: 0.6rem;
    font-weight: 600;
  }

  .top-img-top span {
    font-size: 0.45rem;
    font-weight: 300;
  }

  .top-img .top-img-top:after {
    content: '';
    position: absolute;
    left: 0;
    top: -16px;
    height: 1px;
    width: 40%;
    background: #fff;
  }

  .sin {
    position: fixed;
    right: 1rem;
    top: 50%;
    width: 5px;
  }

  .sin a {
    display: inline-block;
    width: 3px;
    height: 60px;
    background: #d8d8d8;
  }

  .sin a:hover {
    width: 5px;
    background: #b1b0b0;
  }

  .el-popover {
    padding: 0;
  }

  .el-popover .el-popover__title {
    height: 20px;
    font-size: 0.3rem;
    text-align: center;
    align-items: center;
    line-height: 34px;
    color: #6a6969;
  }

  .nav-title-t {
    position: absolute;
    left: 82px;
    bottom: 16px;
    font-size: 26px;
    color: rgba(70, 70, 70, 1);
  }

  .mii .title-box {
    background: #f8f8f8;
    padding: 40px 0;
  }

  .mii .title-box .text {
    font-size: 0.3rem;
    font-weight: normal;
    color: #0fb7d1;
    text-align: left;
    padding: 0 100px;
  }

  .mii .title-box .text:nth-child(1) {
    margin-bottom: 5px;
  }

  .enkt img {
    position: absolute;
    top: 0;
    left: -30px;
    width: 890px;
    height: 100%;
    margin: 0;
  }

  .enkt .enkt-t {
    position: absolute;
    left: 53px;
    bottom: 22px;
    font-size: 24px;
    font-weight: 500;
    color: #464646;
  }

  .mian-right {
    position: relative;
    margin: auto;
    margin-top: 30px;
    padding-bottom: 2rem;
  }

  .mian-right h2 {
    position: absolute;
    right: 0;
    top: 0;
    padding-bottom: 30px;
  }

  .mian-right .nav {
    position: absolute;
    right: 84px;
    top: 82px;
  }

  .mian-right .nav div:nth-child(1) {
    font-size: 0.5rem;
    color: #0fb7d1;
    text-align: left;
    font-weight: bold;
    line-height: 40px;
  }

  .mian-right .nav div:nth-child(2) {
    font-size: 0.5rem;
    color: #464646;
    text-align: left;
    line-height: 25px;
  }

  .enen {
    position: absolute;
    top: 9px;
    left: 6px;
    height: 100px;
  }

  .item-txt-t {
    position: absolute;
    left: 103px;
    top: 53px;
    font-size: 0.3rem;
    font-weight: 400;
  }

  .item-txt-m {
    position: absolute;
    left: 103px;
    top: 64px;
    font-size: 0.1rem;
  }

  .item-txt-m p {
    line-height: 13px;
    text-align: left;
    font-size: 0.1rem;
    line-height: 0.22rem;
  }

  .item-i {
    position: absolute;
    left: 105px;
    top: 22px;
    height: 30px;
  }

  .item-txt-a {
    position: relative;
    background: #f8f8f8;
    margin: 0.2rem 0rem 0px 0.2rem;
  }

  .item-txt-b {
    float: left;
    width: 0.9rem;
    line-height: 0.9rem;
    background: #16b9d2;
    color: #fff;
    font-size: 0.4rem;
    text-align: center;
    position: absolute;
    height: 100%;
  }

  .item-txt-c {
    text-align: left;
    line-height: 0.9rem;
    padding-left: 1.2rem;
    padding-right: 0.2rem;
    font-size: 0.3rem;
  }

  .item-txt-d {
    margin: 0.14rem 0.4rem 0 0.4rem;
    text-align: left;
    padding-left: 1.2rem;
    font-size: 0.3rem;
  }

  .item-txt-box {
    position: relative;
    margin: 0.2rem 0.4rem 0 0.2rem;
  }

  .item-txt-question {
    height: 0.9rem;
    width: 0.9rem;
    line-height: 0.9rem;
    background: #aad044;
    color: #fff;
    float: left;
    font-size: 0.4rem;
    text-align: center;
  }

  .item-txt-answer {
    font-size: 0.27rem;
    padding-left: 1.2rem;
    padding-top: 0.1rem;
    line-height: 0.6rem;
    color: #6a6969;
    text-align: left;
  }

  /*.item-header{*/
  /*  padding-top: 10px;*/
  /*  width: 800px;*/
  /*}*/
  /*.item-header .item-header-s span{*/
  /*  display: inline-block;*/
  /*  width: 250px;*/
  /*  background: #f8f8f8;*/
  /*  padding: 10px 0;*/
  /*  text-align: center;*/
  /*  color: #b1b0b0;*/
  /*}*/
  /*.item-header .item-header-s span:nth-child(2){*/
  /*  margin: 0 25px;*/
  /*}*/
  /*.item-header .item-header-text{*/
  /*  padding: 10px  23px 0 23px;*/
  /*  text-align: left;*/
  /*}*/
  /*.item-header .item-header-text p*/
  /*{*/
  /*  text-indent:2em;*/
  /*  color: #b1b0b0;*/
  /*  padding-bottom: 10px;*/
  /*  margin-bottom: 0;*/
  /*}*/
  /*.copyright-info{*/
  /*  margin: 30px 0;*/
  /*}*/
  /*.copyright-info .copyright-info-t{*/
  /*  display: inline-block;*/
  /*  margin-top: 25px;*/
  /*  width: 550px;*/
  /*  color: #6a6969;*/
  /*}*/
  /*.copyright-info .copyright-info-t span{*/
  /*  display: inline-block;*/
  /*  width: 100%;*/
  /*  text-align: left;*/
  /*}*/
  /*.copyright-info .copyright-info-t p{*/
  /*  padding-top: 17px;*/
  /*  text-align: left;*/
  /*}*/
  /*.copyinfo-info-code{*/
  /*  float: right;*/
  /*  margin-right: 75px;*/
  /*}*/
  /*.copyinfo-info-code p{*/
  /*  margin: 0 auto;*/
  /*  padding: 0;*/
  /*}*/
  /*.copy-title{*/
  /*  color: #0fb7d1;*/
  /*  font-weight: normal;*/
  /*  text-align: left;*/
  /*}*/
  /*.copy-introduce p{*/
  /*  height: 500px;*/
  /*}*/
  /*.copy-introduce .flow-img{*/
  /*  margin: 50px 0;*/
  /*}*/
  /*.copy-introduce .copy-statute-link img{*/
  /*  padding: 0 10px;*/
  /*}*/
  .item-header .item-header-s .span {
    display: inline-block;
    width: 100%;
    background: #f8f8f8;
    padding: 10px 0;
    text-align: center;
    color: #b1b0b0;
  }

  .item-header .item-header-s span:nth-child(2) {
    margin: 0 25px;
  }

  .item-header .item-header-text {
    padding: 20px 15px 0 15px;
    text-align: left;
    font-size: 0.27rem;
    line-height: 24px;
  }

  .item-header .item-header-text p {
    text-indent: 2em;
    color: #b1b0b0;
    padding-bottom: 10px;
  }

  .copyright-info {
    margin: 30px 0;
  }

  .copyright-info .copyright-info-t {
    display: inline-block;
    float: left;
    margin-top: 25px;
    color: #6a6969;
    width: 70%;
  }

  .copyright-info .copyright-info-t span {
    display: inline-block;
    width: 100%;
    text-align: left;
  }

  .copyright-info .copyright-info-t p {
    padding-top: 17px;
    text-align: left;
  }

  .copyinfo-info-code {
    float: right;
    margin-right: 0.5rem;
  }

  .copyinfo-info-code p {
    margin: 0 auto;
    padding: 0;
  }

  .copy-title {
    color: #0fb7d1;
    font-weight: normal;
    text-align: left;
    font-size: 0.4rem;
    margin: 10px 0;
    padding-left: 0.4rem;
  }

  .item-txt-t p {
    line-height: 0.45rem;
    text-align: left;
  }

  .copy-title:not(:first-child) {
    margin-top: 1.2rem;
  }

  .copy-introduce p {
    height: 500px;
  }

  .copy-introduce .flow-img {
    margin: 50px 0;
  }

  .copy-introduce .copy-statute-link {
    margin: 0.5rem 0;
    display: inline-block;
  }

  .go-top {
    position: fixed;
    bottom: 20px;
    right: 50px;
    vertical-align: middle;
  }

  .downn {
    position: absolute;
    right: 144px;
    top: 332px;
    color: #0fb7d1;
  }

  .el-menu-vers {
    width: 10px;
    min-height: 400px;
  }

  .el-tabs__nav-wrap .el-tabs__nav {
    width: 100%;
    padding-bottom: 0.4rem;
  }

  .el-tabs__item {
    color: #b9b9b9;
    padding: 0;
  }

  .mian-left-l .el-tabs__header {
    height: 85px;
  }

  .mian-left-l .el-tabs__nav-wrap {
    background: #f8f8f8;
  }

  .mian-left-l .el-tabs__item {
    height: 85px;
    width: 50%;
    line-height: 85px;
    font-size: 0.3rem;
  }

  .mian-left-l .el-tabs__active-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 50% !important;
    background: #0fb7d1;
  }

  .mian-left-l .el-tabs__item:hover {
    color: #6a6969;
  }

  .el-tabs__content .el-tabs__header .el-tabs__item:hover {
    color: #6a6969;
  }
  .el-tabs__header .el-tabs__item:hover {
    color: #0fb7d1;
  }
  .el-tabs__header .el-tabs__item.is-active:hover {
    color: #fff;
  }

  .mian-left-l .el-tabs__item .is-active {
    background: #0fb7d1;
  }

  .mian-left-l .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-collapse-item__header {
    position: relative;
    border: none;
    height: 110px;
    margin-top: 27px;
    background: #f8f8f8;
    padding: 10px;
    color: #6a6969;
  }
  .el-collapse-item__header:hover {
    color: #303133;
  }
  .el-collapse {
    border: none;
  }

  .el-collapse-item__arrow {
    font-size: 0.6rem;
    color: #6e6d6d;
    position: absolute;
    top: 55%;
    right: 10%;
  }

  .is-right > .el-tabs__item {
    margin-left: 10px;
    border-bottom: 1px solid #87dbe8;
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
  }

  .is-right > .is-active {
    background: #0fb7d1;
    color: #fff;
  }

  .is-right > .is-active:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 8.7px 5px 0;
    border-color: transparent #0fb7d1 transparent transparent;
    content: '';
    position: absolute;
    z-index: 50;
    top: 43%;
    left: -5px;
  }

  .is-right > .el-tabs__nav-wrap {
    margin-top: 40px;
  }

  .is-right > .el-tabs__active-bar {
    border: none;
  }

  .is-right > .el-tabs__nav-wrap::after {
    height: 0;
  }

  .is-right > .el-tabs__content {
    position: relative;
  }

  /*税务*/
  .tab {
    margin-top: 20px;
  }

  .tab .el-row {
    margin-bottom: 10px;
  }

  .tab .tab-header-td {
    position: relative;
    font-size: 0.3rem;
    height: 1.1rem;
    line-height: 1.1rem;
    color: #fff;
    background: #0fb7d1;
  }

  .tab .el-row .el-col:not(:last-child) .tab-header-td:after {
    content: '';
    position: absolute;
    top: 15%;
    right: 0;
    height: 70%;
    border-right: 1px solid #f8f8f8;
  }

  .tab .tab-content-tr {
    display: flex;
    background: #cdf2f8;
  }

  .tab .tab-content-td {
    position: relative;
    height: 100%;
    color: #6a6969;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0.5rem;
  }

  .tab .el-row .el-col:last-child .tab-content-td {
    text-align: left;
  }

  .tab .el-row .el-col:not(:last-child) .tab-content-td:after {
    content: '';
    position: absolute;
    top: 8%;
    right: 0;
    height: 85%;
    border-right: 1px solid #b4d0d4;
  }

  .el-tabs--right .el-tabs__header.is-right {
    margin-top: 90px !important;
    position: relative;
    right: 62px;
  }

  /*补贴政策*/
  .policy-centent-box {
    position: relative;
    display: flex;
    padding: 20px 0;
    margin: 0.5rem 0;
    /*z-index: -2;*/
  }

  .policy-centent-box:nth-child(2n + 1) {
    background: #f8f8f8;
  }

  .policy-centent-box .bg-number {
    position: absolute;
    top: 0;
    left: 5%;
    font-size: 2rem;
    color: rgba(241, 241, 241, 1);
    z-index: -1;
  }

  .policy-centent-box .policy-centent .main-title {
    font-size: 0.4rem;
    text-align: left;
    color: rgb(106, 105, 105);
    margin-top: 1.3rem;
    margin-left: 6%;
  }

  .policy-centent-box .policy-centent .sub-title {
    text-align: left;
    color: #0fb7d1;
    font-size: 0.4rem;
    margin-left: 6%;
    margin-top: 0.5rem;
  }

  .policy-centent-box .policy-centent .content-txt p {
    text-align: left;
    color: #464646;
    font-size: 0.3rem;
    margin: 0 6%;
    line-height: 0.6rem;
  }

  .policy-centent-box .policy-centent .content-txt p:first-child {
    margin-top: 0.1rem;
  }

  .policy-centent-box .code-box {
    position: relative;
    height: 100%;
  }

  .policy-centent-box .code-box .code {
    position: absolute;
    bottom: 52px;
    left: -26px;
  }

  .policy-centent-box .code-box .code-txt {
    position: absolute;
    bottom: 19px;
    left: 6px;
    font-size: 0.25rem;
    color: rgb(70, 70, 70);
  }

  /*设立指引*/
  .set-guid {
    padding-top: 1rem;
  }

  .circuit-content {
    width: 70%;
    margin: 0.5rem auto;
  }

  .set-guid .copy-title span {
    color: #6a6969;
    font-size: 0.25rem;
  }

  .circuit-content p {
    text-align: left;
    color: #6a6969;
    font-size: 0.3rem;
    margin-top: 5px;
  }

  .materialList-box {
    background: #f8f8f8;
    padding: 0.4rem;
  }

  .set-guid .list-content {
    padding: 0 0.4rem;
  }

  .set-guid .list-content p {
    text-align: left;
  }

  .loginHall-box {
    padding: 0.4rem;
    margin-top: 0.7rem;
    background: #f8f8f8;
  }

  .loginHall-box .tab .el-row:nth-child(2n + 1) .tab-content-tr {
    background: #eef8cf;
  }

  .loginHall-box .title-note {
    text-align: left;
    color: #6a6969;
  }

  .loginHall-box .tab {
    margin: 0;
  }

  .loginHall-box .copy-title,
  .materialList-box .copy-title {
    padding-left: 0;
  }

  /*签证*/
  .materialList-box .subTitle,
  .materialList-box .content-box p {
    text-align: left;
    font-size: 0.25rem;
    color: #696969;
    line-height: 0.5rem;
  }

  .set-guid .code-box {
    width: 33.3%;
    margin: 1rem auto 0 auto;
  }

  .set-guid .code-box .code-txt p {
    text-align: center;
    color: #0fb7d1;
  }

  /*手机端头部导航*/

  .header-nav .main-h-nav {
    width: 33.333%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }

  .header-nav {
    position: fixed;
    top: 1.1rem;
    width: 100%;
    z-index: 999;
    border-top: 1px solid #10b7d1;
    border-bottom: 1px solid #10b7d1;
    background: #222728f5;
    padding: 0 0.2rem;
    font-size: 0.35rem;
  }

  .header-nav .sub-h-nav {
    height: 1rem;
  }

  .header-nav .sub-h-nav div {
    height: 100%;
    line-height: 1rem;
    color: #c8c8c8;
  }

  .header-nav .sub-h-nav div:active {
    color: #10b7d1;
  }

  .header-nav .sub-h-nav div:hover {
    color: #10b7d1;
  }

  .main-h-nav .el-submenu__title {
    height: 1rem;
    background: none !important;
  }

  .main-h-nav .el-submenu__title span {
    line-height: 1rem;
    display: inherit;
  }

  .el-submenu__title:focus,
  .el-submenu__title:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  .el-menu-item-group__title {
    display: none;
  }

  .el-menu-item-group ul {
    background: rgba(0, 0, 0, 0.7);
  }

  .el-menu-item:focus,
  .el-menu-item {
    background: none !important;
  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    color: #10b7d1 !important;
  }

  /*修改版*/
  .main-h-nav1 .menu {
    height: 1rem;
    line-height: 1rem;
    color: #c8c8c8;
    text-align: right;
  }

  .main-h-nav1 .menu span {
    padding-left: 0.2rem;
  }

  .main-h-nav1 .menu i {
    transition: transform 0.3s;
  }

  .main-h-nav1 .menu .is-open {
    transform: rotate(90deg);
  }

  .main-h-nav1 .menu:hover {
    color: #10b7d1;
  }

  .main-h-nav1 .menu-list {
    position: absolute;
    right: -20px;
    width: 56%;
    height: 50rem;
    background: #222728f5;
    color: #c8c8c8;
    text-align: right;
    border-top: 1px solid #10b7d1;
  }

  .main-h-nav1 .menu-list ul {
    margin-top: 0.8rem;
  }

  .main-h-nav1 .menu-list .menu-item {
    line-height: 1.5rem;
    text-align: right;
    padding-right: 20px;
  }

  .main-h-nav1 .menu-list .menu-item:hover {
    color: #10b7d1;
  }

  .main-h-nav1 .menu-list .back-btn {
    display: inline-block;
    margin-right: 20px;
    padding: 0.15rem 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #c8c8c8;
  }

  .main-h-nav1 .menu-list .back-btn span {
    padding-left: 5px;
  }

  .menu-fade-enter-active {
    animation: fadeInRightBig 0.5s;
  }

  .menu-fade-leave-active {
    animation: fadeInRightBig 0.5s reverse;
  }

  .el-tabs__content .el-tabs__nav-wrap .el-tabs__item.is-active {
    color: #0fb7d1;
  }

  @keyframes fadeInRightBig {
    from {
      opacity: 0;
      -webkit-transform: translate3d(2000px, 0, 0);
      transform: translate3d(2000px, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @media screen and (min-width: 990px) {
    .item-header-s .el-row {
      display: flex;
      justify-content: center;
    }

    .item-header-s .el-row .el-col:not(:first-child) {
      margin-left: 4.166667%;
    }

    .hidden-md-and-u {
      display: none;
    }
  }

  @media screen and (max-width: 990px) {
    .item-txt-t {
      top: 53px;
    }

    .hidden-md-and-d {
      display: none;
    }

    .title-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-header .item-header-s .span {
      margin-bottom: 10px;
    }

    .item-header .item-header-text {
      padding: 20px 0 0 0 !important;
    }

    .top-img .ec-top-foot {
      display: none;
    }

    .tab {
      padding: 0;
    }

    .policy-centent-box {
      display: inline-block;
    }

    .policy-centent-box .code-box .code {
      position: static;
      width: 50%;
      margin: 20px auto 0 auto;
    }

    .policy-centent-box .code-box .code-txt {
      position: static;
    }
    .set-guid .code-box p{
      margin: 0 -1rem;
    }
    .el-tabs--right .el-tabs__header.is-right,
    .downn,
    .nav {
      display: none;
    }

    .copyright-info .copyright-info-t {
      float: none;
    }

    .copyinfo-info-code {
      float: none;
      margin-top: 0.5rem;
      margin-right: 0;
    }
    .copyinfo-info-code img{
      width: auto;
      display: block;
      margin: 0 auto;
    }
    .mii #tab-first:before,
    .mii #tab-second:before {
      /* width: .5518rem !important;
              height: .5518rem !important;
              margin: -.3359rem 0 0 0 !important;
              left: .4rem !important; */
      display: block !important;
      transform: translate(0, 0) !important;
      position: initial !important;
    }

    .mii #tab-first,
    .mii #tab-second {
      min-height: 2rem !important;
      /* 			text-indent: 1.3rem !important;*/
      text-align: center !important;
      font-size: 0.42rem;
      padding-bottom: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mii #pane-1 .tit-name,
    .mii #pane-2 .tit-name {
      /* left: 0rem !important;
              width: 100% !important;
              word-break: break-word !important;
              white-space: normal !important;
              line-height: 1 !important;
              padding: 1.25rem .3rem 0 !important; */
      display: none !important;
    }

    .mii #tab-first .tit,
    .mii #tab-second .tit {
      display: inline-block !important;
      margin: 0;
      white-space: normal;
      line-height: 1;
      width: 70%;
      padding: 0;
      text-align: left;
      padding-left: 0.2rem;
      text-indent: 0;
      font-size: 0.4rem !important;
      position: initial !important;
    }

    .mii #pane-1 .tit::before,
    .mii #pane-2 .tit::before {
      display: none !important;
    }

    .el-tabs__nav-wrap .el-tabs__nav {
      padding-bottom: 0 !important;
    }
    .mii .el-tabs__content #tab-first .tit::before,
    .mii .el-tabs__content #tab-second .tit::before {
      top: 0 !important;
      margin: 0 !important;
    }

    .el-tabs__nav-wrap .el-tabs__nav {
      padding-bottom: 0 !important;
    }
  }

  @media screen and (max-width: 760px) {
    .el-collapse-item__arrow {
      right: 0;
    }

    .title-box .text {
      padding: 0 20px !important;
    }

    .el-submenu .el-menu {
      width: 200px;
      margin-left: -64px;
    }

    .mii #tab-first:before,
    .mii #tab-second:before {
      /* width: .5518rem !important;
              height: .5518rem !important;
              margin: -.3359rem 0 0 0 !important;
              left: .4rem !important; */
      display: block !important;
      transform: translate(0, 0) !important;
      position: initial !important;
    }

    .mii #tab-first,
    .mii #tab-second {
      min-height: 2rem !important;
      /* 			text-indent: 1.3rem !important;*/
      text-align: center !important;
      font-size: 0.42rem;
      padding-bottom: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mii #pane-1 .tit-name,
    .mii #pane-2 .tit-name {
      /* left: 0rem !important;
              width: 100% !important;
              word-break: break-word !important;
              white-space: normal !important;
              line-height: 1 !important;
              padding: 1.25rem .3rem 0 !important; */
      display: none !important;
    }

    .mii #tab-first .tit,
    .mii #tab-second .tit {
      display: inline-block !important;
      margin: 0;
      white-space: normal;
      line-height: 1;
      width: 70%;
      padding: 0;
      text-align: left;
      padding-left: 0.2rem;
    }

    .mii #pane-1 .tit::before,
    .mii #pane-2 .tit::before {
      display: none !important;
    }

    .el-tabs__nav-wrap .el-tabs__nav {
      padding-bottom: 0 !important;
    }
    .mii .el-tabs__content #tab-first .tit::before,
    .mii .el-tabs__content #tab-second .tit::before {
      top: 0 !important;
      margin: 0 !important;
    }
  }

  .el-tabs__nav-wrap:after {
    display: none;
  }

  .is-right > .el-tabs__active-bar {
    background: transparent;
  }

  .mii .el-collapse-item__wrap {
    border-bottom: none;
  }
  .mii #tab-first,
  .mii #tab-second {
    font-size: 0.3rem;
    position: relative;
    /* min-height: 2rem; */
    text-align: left;
    text-indent: 1.2rem;
    height: 100%;
    padding-bottom: 12px;
  }

  .mii #pane-1 #tab-first:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon1.png) no-repeat center 0/100% 100%;
    transform: translate(0, -25%);
    top: 50%;
    left: 0.35rem;
  }

  .mii #pane-1 #tab-second:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon2.png) no-repeat center 0/100% 100%;
    transform: translate(0, -25%);
    top: 50%;
    left: 0.35rem;
  }

  .mii #pane-2 #tab-first:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon3.png) no-repeat center 0/100% 100%;
    transform: translate(0, -25%);
    top: 50%;
    left: 0.35rem;
  }

  .mii #pane-2 #tab-second:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon4.png) no-repeat center 0/100% 100%;
    transform: translate(0, -25%);
    top: 50%;
    left: 0.35rem;
  }

  .mii #pane-1 #tab-first.is-active:before,
  .mii .el-tabs__content #pane-1 #tab-first.is-active .tit::before {
    background: url(../../assets/images/D/tab-icon1-act.png) no-repeat center 0/100% 100% !important;
  }

  .mii #pane-1 #tab-second.is-active:before,
  .mii .el-tabs__content #pane-1 #tab-second.is-active .tit::before {
    background: url(../../assets/images/D/tab-icon2-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 #tab-first.is-active:before,
  .mii .el-tabs__content #pane-2 #tab-first.is-active .tit::before {
    background: url(../../assets/images/D/tab-icon3-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 #tab-second.is-active:before,
  .mii .el-tabs__content #pane-2 #tab-second.is-active .tit::before {
    background: url(../../assets/images/D/tab-icon4-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .mian-left-l #tab-first.el-tabs__item:hover::before,
  .mii #pane-1 .mian-left-l #tab-first:hover .tit::before {
    background: url(../../assets/images/D/tab-icon1-hover.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .mian-left-l #tab-second.el-tabs__item:hover::before,
  .mii #pane-1 .mian-left-l #tab-second:hover .tit::before {
    background: url(../../assets/images/D/tab-icon2-hover.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 .mian-left-l #tab-first.el-tabs__item:hover::before,
  .mii #pane-2 .mian-left-l #tab-first:hover .tit::before {
    background: url(../../assets/images/D/tab-icon3-hover.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 .mian-left-l #tab-second.el-tabs__item:hover::before,
  .mii #pane-2 .mian-left-l #tab-second:hover .tit::before {
    background: url(../../assets/images/D/tab-icon4-hover.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .mian-left-l #tab-first.el-tabs__item.is-active:hover::before,
  .mii .el-tabs__content #pane-1 .mian-left-l #tab-first.is-active:hover .tit::before {
    background: url(../../assets/images/D/tab-icon1-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .mian-left-l #tab-second.el-tabs__item.is-active:hover::before,
  .mii .el-tabs__content #pane-1 .mian-left-l #tab-second.is-active:hover .tit::before {
    background: url(../../assets/images/D/tab-icon2-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 .mian-left-l #tab-first.el-tabs__item.is-active:hover::before,
  .mii .el-tabs__content #pane-2 .mian-left-l #tab-first.is-active:hover .tit::before {
    background: url(../../assets/images/D/tab-icon3-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 .mian-left-l #tab-second.el-tabs__item.is-active:hover::before,
  .mii .el-tabs__content #pane-2 .mian-left-l #tab-second.is-active:hover .tit::before {
    background: url(../../assets/images/D/tab-icon4-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .tit-name,
  .mii #pane-2 .tit-name {
    /* position: absolute; */
    display: none;
    font-size: 0.1rem;
    top: 0;
    left: 0;
    white-space: normal;
    word-break: break-word;
    line-height: initial;
    text-indent: 0;
    padding: 0px 0 0 65px;
    margin: -30px 0 0 0;
    /* max-height: 1rem; */
  }

  .mii #pane-2 #tab-first .tit-name {
    left: 0;
  }

  .mii .el-tabs__content #tab-first .tit,
  .mii .el-tabs__content #tab-second .tit {
    display: block;
    position: relative;
    top: 0.3rem;
  }

  .mii .el-tabs__content #pane-1 #tab-first .tit::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon1.png) no-repeat center 0/100% 100%;
    margin: 0 0.4rem 0 0;
    top: 0.27rem;
    display: none;
  }

  .mii .el-tabs__content #pane-1 #tab-second .tit::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon2.png) no-repeat center 0/100% 100%;
    margin: 0 0.4rem 0 0;
    top: 0.27rem;
    display: none;
  }

  .mii .el-tabs__content #pane-2 #tab-first .tit::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon3.png) no-repeat center 0/100% 100%;
    margin: 0 0.4rem 0 0;
    top: 0.27rem;
    display: none;
  }

  .mii .el-tabs__content #pane-2 #tab-second .tit::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon4.png) no-repeat center 0/100% 100%;
    margin: 0 0.4rem 0 0;
    top: 0.27rem;
    display: none;
  }

  .mii .el-tabs__content .el-tabs__nav.is-top {
    display: flex;
  }

  .mii .item-header {
    margin-top: 27px;
  }
}
</style>
<style lang="less">
.cityDe {
  .cnts-item {
    text-align: left;
    &.i-one {
      h1 {
        color: #6a6969;
        font-size: 0.3rem;
      }
      i,em {
        color: #0fb7d1;
        font-size: 0.4rem;
        font-style: initial;
        display: block;
        position: relative;
        padding-left: 0.3rem;
      }
      > ul {
        > li {
          padding: 1.4888rem 0 0 0.4rem;
          text-align: justify;
          line-height: 1.75;
          > div {
            i,em {
              color: #0fb7d1;
              font-size: 0.4rem;
              font-style: initial;
              display: block;
              position: relative;
              span {
                position: absolute;
                color: #f1f1f1;
                font-size: 1.5518rem;
                bottom: 0;
                left: 0;
                line-height: 0.9;
                z-index: -1;
              }
            }
            p {
              color: #464646;
              display: block;
              font-size: 0.25rem;
              text-align: left;
            }
            > .one-left-row {
              margin-top: 0.4rem;
              padding-bottom: 1rem;
              > .el-col {
                padding-right: 2%;
                position: relative;
                &:nth-child(2n) {
                  > div {
                    background: #a5cd39;
                  }
                }
                > div {
                  position: relative;
                  background: #0fb7d1;
                  padding: 0.8rem 0.3rem 0.3rem;
                  line-height: initial;
                  height: 100%;
                  &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-width: 0.3rem;
                    border-color: #fff transparent transparent transparent;
                    border-style: solid;
                    z-index: 1;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                  }
                  img {
                    display: block;
                    margin: 0 auto 0.3rem;
                    width: 100%;
                  }
                  h3 {
                    color: #fff;
                    font-size: 0.4rem;
                    display: block;
                  }
                  p {
                    color: #fff;
                    font-size: 0.25rem;
                    padding: 0.1rem 0;
                  }
                }
              }
            }
          }
        }
      }
      .one-left-row2 {
        padding-left: 0.3rem;
        font-size: 0.25rem;
        .left {
          ul {
            padding-bottom: 0.2rem;
            li {
              background: #cff1f6;
              padding: 0.1rem 0 0.1rem 0.2rem;
              &:nth-child(2n) {
                background: #f6f6f6;
              }
            }
          }
          p {
            padding-left: 0.2rem;
          }
        }
        .right {
          padding-left: 1rem;
          p {
          }
          img {
            width: 100%;
          }
        }
      }
    }
    &.i-three3 {
      .cnts1,
      .cnts2 {
        > h1 {
          font-size: 0.4rem;
          color: #0fb7d1;
        }
        > p {
          font-size: 0.25rem;
          color: #6a6969;
        }
        > div {
          padding-bottom: 0.3rem;
          > h3 {
            font-size: 0.4rem;
            color: #6a6969;
          }
          > p {
            font-size: 0.25rem;
            color: #6a6969;
            padding-left: 0.4rem;
          }
          &:last-child,
          &:first-child {
            > p {
              padding-left: 0;
            }
          }
        }
      }
      .cnts2 {
        background: #f8f8f8;
        padding: 0.7rem 0.4rem 0;
        margin: 0 -0.4rem 0;
      }
    }
    &.i-four {
      ul {
        li {
          .item-txt-a {
            .item-txt-b {
              text-align: center;
            }
          }
          .item-txt-box {
            display: flex;
            &::after {
              content: '';
              display: block;
              clear: both;
            }
          }
          .item-txt-answer {
            padding-left: 0.2rem;
            .cnts {
              display: inline-block;
              text-align: center;
              img {
                width: auto;
              }
              p {
                color: #6a6969;
                font-size: 0.25rem;
              }
            }
          }
          .item-txt-question {
            height: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    &.i-five {
      > div {
        padding-top: 0.4rem;
        h1 {
          color: #0fb7d1;
          font-size: 0.4rem;
        }
        p {
          color: #6a6969;
          font-size: 0.25rem;
        }
        &.cnt2 {
          background: #f8f8f8;
          padding: 0.4rem 0.4rem;
        }
        ul {
          li {
            .item-txt-a {
              margin-left: 0;
              .item-txt-b {
                text-align: center;
              }
            }
            .item-txt-box {
              margin-left: 0;
              .item-txt-question {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &.i-six {
      > div {
        padding-top: 0.4rem;
        h1 {
          color: #0fb7d1;
          font-size: 0.4rem;
          span {
            font-size: 0.25rem;
            display: block;
          }
        }
        p {
          color: #6a6969;
          font-size: 0.25rem;
        }
        &.cnt2 {
          display: flex;
          align-items: center;
          padding: 0.4rem 0.4rem 0;
          img {
            width: auto;
          }
          p {
            color: #6a6969;
            font-size: 0.25rem;
          }
          .right {
            padding-left: 0.4rem;
          }
        }
        ul {
          li {
            .item-txt-a {
              margin-left: 0;
              .item-txt-b {
                text-align: center;
              }
            }
            .item-txt-box {
              margin-left: 0;
              .item-txt-question {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &.i-seven {
      h1 {
        font-size: 0.4rem;
        color: #0fb7d1;
        padding-top: 0.4rem;
      }
      .tab {
        text-align: center;
        .tab-content-tr{
          div{
            &:nth-child(2){
              .tab-content-td{
                text-align: left;
              }
            }
          }
        }
        .tab-content-td {
          flex-wrap: wrap;
          .box {
            width: 100%;
            padding-top: 0.2rem;
            .left {
              white-space: nowrap;
            }
            img {
              width: auto;
              display: block;
              margin: 0 auto;
            }
            p {
              display: block;
              margin: 0 auto;
            }
          }
        }
        &.b2{
          p {
            text-align: center;
          }
          .tab-content-tr{
            div{
              &:nth-child(2){
                .tab-content-td{
                  p{
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.i-eit2 {
      p {
        font-size: 0.25rem;
      }
      .cnts2 {
        background: #f8f8f8;
        padding: 0.4rem;
        margin: 0.4rem 0 0 0;
      }
    }
    &.i-eit3 {
      img {
        width: auto;
        display: block;
        margin: 0.6rem auto;
      }
      > div {
        padding-bottom: 0.4rem;
      }
      p {
        font-size: 0.25rem;
        color: #696969;
        line-height: 2;
      }
      i,em {
        display: block;
        font-size: 0.25rem;
        color: #0fb7d1;
        line-height: 2;
        font-style: initial;
        text-align: left;
      }
      h1 {
        font-size: 0.4rem;
        color: #0fb7d1;
        span {
          font-size: 0.25rem;
          color: #6a6969;
        }
      }
      .cnts2 {
        background: #f8f8f8;
        padding: 0.4rem;
        margin: 0.4rem 0 0.4rem 0;
      }
      .tab {
        text-align: center;
        margin: 0;
      }
      ul {
        li {
          .item-txt-a {
            margin-left: 0;
            height: auto;
            display: flex;
            .item-txt-b {
              text-align: center;
              height: 100%;
              display: flex;
              justify-content: center;
            }
            .item-txt-c {
              height: auto;
              /*padding-left: .28rem;*/
            }
          }
          .item-txt-box {
            margin-left: 0;
            .item-txt-question {
              text-align: center;
            }
            .cnts {
              line-height: 0.7rem;
            }
          }
        }
      }
    }
    &.i-night2 {
      > div {
        padding-top: 0.4rem;
        h1 {
          font-size: 0.4rem;
          color: #0fb7d1;
          span {
            font-size: 0.25rem;
            color: #6a6969;
          }
        }
        p {
          font-size: 0.25rem;
          color: #696969;
          line-height: 2;
        }
        i,em {
          display: block;
          font-size: 0.25rem;
          color: #696969;
          line-height: 2;
          font-style: initial;
          text-indent: 1.5em;
        }
        img {
          width: auto;
          display: block;
          margin: 0.3703rem auto;
          max-width: 100%;
        }
      }
    }
    &.i-night3 {
      > div {
        h1 {
          font-size: 0.4rem;
          color: #0fb7d1;
          span {
            font-size: 0.25rem;
            color: #6a6969;
          }
        }
        ul {
          li {
            .item-txt-answer {
              .cnts {
                text-align: center;
                display: inline-block;
                img {
                  width: auto;
                }
                p {
                  font-size: 0.25rem;
                  color: #6a6969;
                  text-align: left;
                  line-height: 1.75;
                }
              }
            }
          }
        }
      }
    }
  }
  .txt-content {
    /*padding-left: 0.4rem;*/
    text-align: left;
    p {
      height: unset;
      font-size: 0.3rem;
    }
    .number-list {
      margin-top: 0.74rem;
      padding-left: 0.4rem;
      h1 {
        font-size: 0.3rem;
      }
      p {
        height: unset;
        font-size: 0.3rem;
        margin-left: 0.2rem;
        margin-top: 0.1rem;
      }
    }
  }
  .copy-introduce {
    margin-top: 1.3rem;
    .copy-statute-link {
      display: flex;
      flex-wrap: wrap;
      .left {
        margin-right: 0.2rem;
        position: relative;
        color: #00b6cd;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 40%;
          border-top: 3px solid #00b6cd;
        }
      }
      .right {
        margin-left: 0.2rem;
        position: relative;
        color: #a5cd39;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 40%;
          border-top: 3px solid #a5cd39;
        }
      }
      .link-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border: 1px solid #d8d8d8;
        padding: 1.5rem 0.6rem 1.8rem 0.6rem;
        text-align: left;
        .txt-box {
          font-size: 0.4rem;
          align-self: self-start;
          flex: 1;
          p {
            height: auto;
          }
        }
        .link-icon {
          margin-top: 0.1rem;
          align-self: self-end;
        }
      }
      a {
        /*display: ;*/
        color: unset;
      }
    }
    .flow-img {
      width: 100%;
    }
    img {
      width: unset;
    }
  }
  .question {
    padding-top: 0.5rem;
  }
  .D2-02-02 {
    text-align: left;
    .top-info-box {
      margin: 0.8rem 0;
      p {
        font-size: 0.3rem;
        color: #6a6969;
        word-break: break-word;
      }
    }
    .eg-box {
      .eg-txt-content {
        .number-list {
          h1 {
            font-size: 0.3rem;
            padding-left: 0.4rem;
            &:not(:first-child) {
              margin-top: 0.5rem;
            }
          }
          h2 {
            font-size: 0.3rem;
            padding-left: 0.55rem;
            padding-bottom: 0;
            position: inherit;
            margin-top: 0.2rem;
          }
          p {
            font-size: 0.3rem;
            padding-left: 0.55rem;
            margin-top: 0.1rem;
          }
        }
      }
    }
    .flowChart-box {
      margin-top: 1rem;
      .box-img {
        margin-top: 0.5rem;
      }
    }
  }
  @media screen and (max-width: 990px) {
    .item-header .item-header-s .span {
      font-size: 0.35rem;
    }
    .item-header .item-header-text {
      font-size: 0.35rem;
    }
    .copyright-info .copyright-info-t {
      font-size: 0.35rem;
      width: 100%;
    }
    .copy-title {
      font-size: 0.45rem;
    }
    .txt-content p {
      font-size: 0.35rem;
    }
    .txt-content .number-list h1 {
      font-size: 0.35rem;
    }
    .txt-content .number-list p {
      font-size: 0.35rem;
    }
    .item-txt-c {
      font-size: 0.35rem;
    }
    .item-txt-answer {
      font-size: 0.32rem;
    }
    .D2-02-02 .top-info-box p {
      font-size: 0.35rem;
    }
    .D2-02-02 .eg-box .eg-txt-content .number-list h1 {
      font-size: 0.35rem;
    }
    .D2-02-02 .eg-box .eg-txt-content .number-list h2 {
      font-size: 0.35rem;
    }
    .D2-02-02 .eg-box .eg-txt-content .number-list p {
      font-size: 0.35rem;
    }
    .item-txt-t {
      font-size: 0.35rem;
    }
    .item-txt-m {
      font-size: 0.25rem;
    }
    .tab .tab-content-td {
      padding: 0.4rem 0.2rem;
    }
    .circuit-content {
      width: 90%;
    }
    .set-guid .copy-title span {
      font-size: 0.3rem;
    }
    .mii .title-box .text {
      font-size: 0.35rem;
    }
    .circuit-content p {
      font-size: 0.35rem;
    }
    .materialList-box .subTitle,
    .materialList-box .content-box p {
      font-size: 0.3rem;
    }
  }
  @media screen and (max-width: 776px) {
    .copy-introduce .copy-statute-link {
      .left {
        margin-right: 0;
      }
      .right {
        margin-left: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
@media screen and (max-width: 990px) {
  .cityDe {
    .cnts-item {
      &.i-one {
        h1 {
          font-size: 0.4444rem;
        }
        > ul {
          > li {
            padding-left: 0;
            padding-top: 0.6rem;
            > div {
              > i {
                font-size: 0.4444rem;
              }
              > p {
                font-size: 0.3703rem;
              }
              > .one-left-row {
                > .el-col {
                  padding-right: 0;
                  margin-bottom: 0.4rem;
                  > div {
                    padding: 0.3rem;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    &::before {
                      display: none;
                    }
                    .left {
                      width: 40%;
                      padding-left: 0.4rem;
                      img {
                        margin-bottom: 0;
                      }
                    }
                    .right {
                      width: 60%;
                      text-align: left;
                      h3 {
                        font-size: 0.4444rem;
                      }
                      p {
                        padding-top: 0.4rem;
                        font-size: 0.3703rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .h-1 {
          margin: 0 -0.4rem 0;
        }
        .i-1 {
          padding-top: 0.3rem !important;
        }
        .one-left-row2 {
          padding-left: 0;
          .left {
            ul {
              li {
                font-size: 0.3333rem;
              }
            }
            p {
              font-size: 0.3333rem;
            }
          }
          .right {
            padding: 0;
            display: flex;
            flex-direction: column-reverse;
            img {
              width: 50%;
              display: block;
              margin: 0.2rem auto 0.2rem;
            }
            p {
              font-size: 0.3333rem;
              padding: 0 !important;
              text-align: center;
            }
          }
        }
        i {
          font-size: 0.4444rem;
          padding-left: 0 !important;
        }
      }
      &.i-three3 {
        .cnts1 {
          > h1 {
            font-size: 0.4444rem;
          }
          > div {
            > p {
              font-size: 0.3703rem;
            }
          }
        }
        .cnts2 {
          > h1 {
            font-size: 0.4444rem;
          }
          > div {
            > p {
              font-size: 0.3703rem;
            }
          }
        }
      }
      &.i-four {
        ul {
          li {
            .item-txt-a {
              margin-left: 0;
              height: auto;
              .item-txt-b {
                position: absolute;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .item-txt-c {
                height: auto;
                font-size: 0.3703rem;
              }
            }
            .item-txt-box {
              margin: 0.2rem 0 0;
            }
            .item-txt-answer {
              .cnts {
                p {
                  font-size: 0.3703rem;
                }
              }
            }
          }
        }
      }
      &.i-five {
        > div {
          p {
            font-size: 0.3703rem;
          }
        }
      }
      &.i-six {
        > div {
          p {
            font-size: 0.3703rem;
          }
          h1 {
            span {
              font-size: 0.3703rem;
            }
          }
          &.cnt2 {
            flex-direction: column;
            .left {
              width: 100%;
              img {
                display: block;
                margin: 0 auto;
                width: 60%;
              }
            }
            .right {
              width: 100%;
              padding-left: 0;
              padding-top: 0.6rem;
              p {
                font-size: 0.3703rem;
                text-align: center;
              }
            }
          }
        }
      }
      &.i-seven {
        word-break: break-word;
        .tab {
          .tab-content-td {
            .box {
              img {
                width: 60%;
              }
            }
          }
        }
      }
      &.i-eit2 {
        p {
          font-size: 0.3703rem;
        }
      }
      &.i-eit3 {
        p {
          font-size: 0.3703rem;
        }
        i {
          font-size: 0.3703rem;
        }
        img {
          width: 100%;
        }
      }
      &.i-night2 {
        > div {
          h1 {
            font-size: 0.4444rem;
          }
          p {
            font-size: 0.3703rem;
          }
          i {
            font-size: 0.3703rem;
            text-indent: 1.6em;
          }
        }
      }
      &.i-night3 {
        > div ul li {
          .item-txt-answer {
            padding-left: 0;
            text-align: center;
            .cnts {
              display: block;
              p {
                font-size: 0.3703rem;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
